<template>
  <NavigationComp :isDisable="false" />
  <main class="main">
    <div class="main__wrapper container">
      <h2 class="main__wrapper-title main__wrapper-title--center">
        Лабораторные исследования клещей
      </h2>
      <div class="main__tickResearch">
        <h4 class="main__tickResearch-title">Уважаемые заявители!</h4>
        <ol class="main__tickResearch-orderList">
          <li>
            <span class="main__tickResearch-text--weight">
              Оформить заявку онлайн на исследование клещей можно
              воспользовавшись кнопкой
              <router-link to="/formForTickResearch"
                >«Оформление заявки»</router-link
              >
              .
            </span>
          </li>
          <li>
            <span class="main__tickResearch-text--weight"
              >Сдать клеща на исследование можно по адресу:</span
            >
            Запорожская область, г. Мелитополь, ул. Сухова, д. 6
          </li>
        </ol>
        <p class="main__tickResearch-text">
          <span class="main__tickResearch-text--weight">Рабочие дни:</span>
        </p>
        <p class="main__tickResearch-text">
          Понедельник – пятница: С 08-00 до 16-30
        </p>
        <p class="main__tickResearch-text">
          Исследования клещей, проводятся только
          <span class="main__tickResearch-text--weight"
            >на договорной основе</span
          >
          .
        </p>
        <p class="main__tickResearch-text">
          <span class="main__tickResearch-text--weight"
            >Стоимость исследования 1 клеща</span
          >
          методом полимеразной цепной реакции (ПЦР) на 4 инфекции: клещевой
          энцефалит, клещевой боррелиоз, анаплазмоз, эрлихиоз –
          <a href="/price#tickPrice"
            ><span class="main__tickResearch-text--weight">узнать цену</span></a
          >
        </p>
        <p class="main__tickResearch-text">
          <span class="main__tickResearch-text--weight">Способы оплаты:</span>
        </p>
        <ul class="main__tickResearch-unOrderList">
          <li>
            При сдаче клеща, банковской картой, через терминал в учреждения (по
            рабочим дням).
          </li>
        </ul>

        <p class="main__tickResearch-text">
          <span class="main__tickResearch-text--weight"
            >Получить консультация по оформлению документов</span
          >
          (заявление, договор, счет на оплату) можно по контактному телефону:
          <span class="main__tickResearch-text--weight"
            >+7 (990) 234-50-40</span
          >
          (рабочие дни).
        </p>
        <ul class="main__tickResearch-unOrderList">
          <li>
            Результат исследований (протокол) можно получить на руки при
            обращении в учреждении.
          </li>
        </ul>

        <router-link class="main__tickResearch-btn" to="/formForTickResearch"
          >Оформление заявки</router-link
        >
      </div>
    </div>
  </main>
</template>

<script>
import NavigationComp from "@/components/UI/Navigation/NavigationComp.vue";

import { useHead } from "@vueuse/head";

export default {
  components: { NavigationComp },
  name: "Исследование клещей",
  data() {
    return {};
  },

  setup() {
    useHead({
      title:
        "Услуги для населения - Исследование клещей | Федеральное бюджетное учреждение здравоохранения «Центр гигиены и эпидемиологии в Запорожской области»",
      meta: [
        {
          name: "documents",
          content:
            "Федеральное бюджетное учреждение здравоохранения «Центр гигиены и эпидемиологии в Запорожской области»",
        },
      ],
    });
  },
};
</script>
