<template>
  <NavigationComp :isDisable="false" />
  <main class="services container">
    <DocumentListComp :documentsListName="'servicesDocuments'" />
    <h1 class="services__title">Дератизация, дезинфекция, дезинсекция</h1>
    <p class="services__text services__text--bold">
      Проводятся работы по дератизации, дезинсекции, дезинфекции, камерной
      дезинфекции и дезодорации.
    </p>
    <p class="services__text">
      Для проведения дезобработок используются эффективные безопасные препараты
      (имеются свидетельства госрегистрации, декларация о соответствии)
    </p>
    <p class="services__text">
      Квалифицированные специалисты, имеющие профессиональную подготовку,
      большой опыт работы, оснащенные современным оборудованием. обеспечат
      высокое качество проводимых дезработ по следующим направлениям:
    </p>

    <h2 class="services__chapterTitle">ДЕРАТИЗАЦИЯ:</h2>
    <ul class="services__ulList">
      <li class="services__ulList--marker">
        на предприятиях по переработке пищевых продуктов, предприятиях
        общественного питания и продовольственных магазинах; в жилых домах,
        детских и лечебных учреждениях, в помещениях животноводческих объектов,
        территории свалок;
      </li>
      <li class="services__ulList--marker">
        определение видового состава и численности грызунов; • подготовка
        комплекса рекомендаций по защите объекта от проникновения грызунов;
      </li>
      <li class="services__ulList--marker">
        использование для истребительных работ препаратов эффектом
        «мумификации»;
      </li>
      <li class="services__ulList--marker">
        определение объёма профилактических и истребительных мероприятий
      </li>
    </ul>

    <h2 class="services__chapterTitle">ДЕЗИНСЕКЦИЯ:</h2>
    <ul class="services__ulList">
      <li class="services__ulList--marker">
        тараканов, клопов, крысиных клещей, муравьев, мух, блох, моли, мошкары,
        комаров, клещей и др.;
      </li>
      <li class="services__ulList--marker">
        акарицидная обработка территорий парков, скверов, зон отдыха, участков
        (иксодовые клещи);
      </li>
      <li class="services__ulList--marker">
        ларвицидная обработка водоемов от малярийных комаров
      </li>
    </ul>
    <h2 class="services__chapterTitle">ДЕЗИНФЕКЦИЯ:</h2>
    <ul class="services__ulList">
      <li class="services__ulList--marker">
        в жилых и производственных помещениях, на предприятиях общественного
        питания и торговли, в санаториях и гостиницах, школах и детских садах, а
        также рынки, склады, офисы и др.;
      </li>
      <li class="services__ulList--marker">
        чердаки, подвалы, мусоропроводы, мусорокамеры, контейнеры для сбора
        мусора;
      </li>
      <li class="services__ulList--marker">
        бассейны, бани, сауны, душевые кабины, емкости;
      </li>
      <li class="services__ulList--marker">
        дезинфекция транспорта, перевозящего пищевые продукты;
      </li>
      <li class="services__ulList--marker">
        дезинфекция в очагах инфекционных заболеваний;
      </li>
    </ul>

    <h2 class="services__chapterTitle">КАМЕРНАЯ ДЕЗИНФЕКЦИЯ:</h2>
    <ul class="services__ulList">
      <li class="services__ulList--marker">
        дезинфекция и дезинсекция постельных принадлежностей, одежды и др. в
        стационарной дезинфекционной камере;
      </li>
    </ul>

    <p class="services__text">
      С предприятиями и частными лицами заключаются систематические и разовые
      договора, договор составляется с соблюдением требований санитарных правил.
    </p>
    <p class="services__text">
      Наши сотрудники проведут санитарные мероприятия в любое время дня, удобное
      для заказчика. Для проведения обработок подбираются лучшие препараты
      отечественного и импортного производства не имеющие запаха и не
      оставляющие следов, своевременно вносятся корректировки и осуществляется
      ротация применяемых препаратов.
    </p>
    <p class="services__text">
      Большой практический опыт и опробованные методики обеспечивают высокое
      качество проводимых работ.
    </p>
    <p class="services__text">
      Дездеятельность построена на максимально удобной для клиентов основе –
      выбор времени и даты проведения санитарных мероприятий осуществляется с
      учетом пожеланий заказчика, соотношение цены и качества являются наиболее
      демократичными на дезинфекционные услуги.
    </p>

    <div class="services__address">
      <p class="services__text services__text--bold">
        Место нахождения: г. Мелитополь ул. Сухова, дом 6;
      </p>
      <p class="services__text services__text--bold">
        Кол-центр: +7-990-234-50-40
      </p>
      <p class="services__text services__text--bold">
        E-mail: fbuz_85@rospotrebnadzor.ru
      </p>
      <p class="services__text services__text--bold">
        Гарантируется выполнение работ качественно и в срок!
      </p>
    </div>

    <img
      class="services__banner"
      src="@/assets/img/content/desinfectionBanner.webp"
      alt="Баннер"
      width="800"
    />
  </main>
</template>

<script>
import NavigationComp from "@/components/UI/Navigation/NavigationComp.vue";
import DocumentListComp from "@/components/UI/Documents/DocumentListComp.vue";
import { useHead } from "@vueuse/head";

export default {
  components: { NavigationComp, DocumentListComp },
  name: "Услуги",

  mounted() {
    if (this.$route.hash == "#priceList") {
      const el = document.querySelector(this.$route.hash);
      if (el) {
        el.scrollIntoView();
      }
    }
  },

  setup() {
    useHead({
      title:
        "Услуги | Федеральное бюджетное учреждение здравоохранения «Центр гигиены и эпидемиологии в Запорожской области»",
      meta: [
        {
          name: "description",
          content:
            "На базе ФБУЗ «Центр гигиены и эпидемиологии в Запорожской области» проводятся работы по дератизации, дезинсекции, дезинфекции, камерной дезинфекции и дезодорации.",
        },
      ],
    });
  },
};
</script>

<style lang="scss" scoped>
.main__document__wrapper {
  margin-bottom: 30px;
}
</style>
