<template>
	<NavigationComp :isDisable="false" />
	<main class="main">
		<div class="main__wrapper main__wrapper-management container">
			<div class="management__menu">
				<router-link to="/management" class="management__menu-link">Руководство</router-link>
				<router-link to="/documents" class="management__menu-link">Документы</router-link>
			</div>
			<div class="management__main">
				<h2 class="management__main-title">Руководство</h2>

				<p class="management__main-text"><span class="management__main-text--underline">Главный врач:</span> Сычев Даниил Александрович</p>
				<p class="management__main-text"><span class="management__main-text--underline">Приёмная:</span>
					<ul class="management__main-list">
						<li><span>тел.:</span> 8 (990) 290 36 38</li>
						<li><span>e-mail:</span> fbuz85@cgezo.ru</li>
						<li><span>e-mail:</span> fbuz_85@rospotrebnadzor.ru</li>
					</ul>
				</p>
				<p class="management__main-text"><span class="management__main-text--underline">График приёма граждан:</span>
					<ul class="management__main-list">
						<li>по предварительной записи: </li>
						<li><span>Вторник</span> 11:00 – 15:00 </li>
						<li><span>Среда</span> 11:00 – 15:00</li>
						<li><span>Четверг</span> 11:00 – 15:00</li>
						<li><span>Обеденный перерыв</span> 12:00 – 12:30</li>
					</ul>
				</p>
			</div>
		</div>
	</main>
</template>

<script>
import NavigationComp from '@/components/UI/Navigation/NavigationComp.vue';
import { useHead } from '@vueuse/head';

export default {
	components: { NavigationComp },
	name: 'Руководство',

	setup() {
		useHead({
			title:
				'Руководство | Федеральное бюджетное учреждение здравоохранения «Центр гигиены и эпидемиологии в Запорожской области»',
			meta: [
				{
					name: 'description',
					content: 'Руководство.',
				},
			],
		});
	},
};
</script>

<style lang="scss" scoped></style>
