<template>
  <NavigationComp :isDisable="false" />
  <ContactCardsListComp />
</template>

<script>
import { useHead } from "@vueuse/head";
import NavigationComp from "@/components/UI/Navigation/NavigationComp.vue";
import ContactCardsListComp from "@/components/UI/Contacts/ContactCardsListComp.vue";

export default {
  components: {
    NavigationComp,
    ContactCardsListComp,
  },
  name: 'Контакты',


  setup() {
    useHead({
      title:
        "Контакты | Федеральное бюджетное учреждение здравоохранения «Центр гигиены и эпидемиологии в Запорожской области»",
      meta: [
        {
          name: "contacts",
          content:
            "Филиалы ФБУЗ «Центр гигиены и эпидемиологии в Запорожской области»",
        },
      ],
    });
  },
};
</script>

<style lang="scss" scoped></style>
