<template>
	<NavigationComp :isDisable="false" />
	<main class="main">
		<div class="main__wrapper main__wrapper-about container">
			<div class="about__menu">
				<router-link to="/management" class="about__menu-link">Руководство</router-link>
				<router-link to="/documents" class="about__menu-link">Документы</router-link>
			</div>
			<div class="about__main">
				<h2 class="about__main-title">Об учреждении</h2>
				<p class="about__main-text"> Федеральное бюджетное учреждение здравоохранение «Центр гигиены и эпидемиологии в Запорожской области» (далее «Бюджетное учреждение») является некоммерческой организацией, обеспечивающей деятельность Управления Федеральной  службы по надзору в сфере защиты прав потребителей и благополучия человека по Запорожской области (далее Управление Роспотребнадзора по Запорожской области). </p>
				<p class="about__main-text">Бюджетное учреждение образовано на основании распоряжения Правительства Российской Федерации от 15 декабря 2022 года № 3921-р.</p>
				<p class="about__main-text">Полное наименование Бюджетного учреждения: Федеральное бюджетное учреждение здравоохранения «Центр гигиены и эпидемиологии в Запорожской области». </p>
				<p class="about__main-text">Сокращенное наименование: ФБУЗ «Центр гигиены и эпидемиологии в Запорожской области».
Место нахождения Бюджетного учреждения: 72309, Запорожская область, город Мелитополь улица Сухова, дом 6.
</p>
				<p class="about__main-text">Функции и полномочия учредителя Бюджетного учреждения осуществляет Федеральная служба по надзору в сфере защиты прав потребителей и благополучия человека (далее – «Роспотребнадзор»).</p>
				<p class="about__main-text">Предметы и цели деятельности Бюджетного учреждения: Осуществление деятельности в соответствии с предметом и целями деятельности, определенными в соответствии с Конституцией РФ, федеральными конституционными законами, федеральными законами, актами Президента РФ и Правительства РФ, международными договорами РФ, актами Федеральной службы Роспотребнадзора, а также Уставом Бюджетного учреждения (утвержден приказом Федеральной службы по надзору в сфере защиты прав потребителей и благополучия человека от 12.05.2023 №276).</p>
				<p class="about__main-text">Лабораторное и экспертное обеспечение надзорных мероприятий Управления Роспотребнадзора по Запорожской области, направленных на поддержание санитарно-эпидемиологического благополучия населения.</p>
				<p class="about__main-text">В структуру ФБУЗ «Центр гигиены и эпидемиологии в Запорожской области» входят 2 филиала.</p>
				<p class="about__main-text">Услуги, предоставляемые Бюджетным учреждением:
					<ul class="about__main-list">
						<li>- оформление, выдача и учет личных медицинских книжек работникам отдельных профессий и организаций, деятельность которых связана с производством, хранением, транспортировкой реализацией пищевых продуктов и питьевой воды, воспитанием и обучением детей, коммунальным и бытовым обслуживанием населения;</li>
						<li>- проведение гигиенического воспитания и обучения граждан, профессиональной гигиенической подготовки должностных лиц и работников организаций;</li>
						<li>- санитарно-эпидемиологические обследования, исследования, испытания, а также токсикологические, гигиенические и иные виды оценок и экспертиз;</li>
						<li>- дезинфекционные, дератизационные и дезинсекционные услуги.</li>
					</ul>
				</p>
				<p class="about__main-text">Имея передовую лабораторную базу, Центр готов оказывать услуги любой сложности различным категориям заказчиков в самые короткие сроки. Договорная деятельность построена на удобстве и прозрачности получения услуг гражданами.</p>
				<p class="about__main-text">Все лаборатории оснащены современным и высокотехнологичным оборудованием и средствами измерений, отвечающих мировым стандартам. Качество результатов исследований подтверждается контролем качества на национальном и международном уровне.</p>
				<p class="about__main-text">Место нахождения: ул. Сухова, д. 6, г. Мелитополь, Запорожская область, 72309.</p>
				<p class="about__main-text">Телефон: <a href="tel:+7(990) 290-36-38">+7(990) 290-36-38</a>  E-mail: <a href="mailto:fbuz85@cgezo.ru">fbuz85@cgezo.ru</a> </p>
			</div>
			
		</div>
	</main>
</template>

<script>
import NavigationComp from '@/components/UI/Navigation/NavigationComp.vue';
import { useHead } from '@vueuse/head';

export default {
	components: { NavigationComp },
	name: 'Об учреждении',

	data() {
		return {
			
		};
	},

	setup() {
		useHead({
			title:
				'Об учреждении | Федеральное бюджетное учреждение здравоохранения «Центр гигиены и эпидемиологии в Запорожской области»',
			meta: [
				{
					name: 'about',
					content:
						'Федеральное бюджетное учреждение здравоохранения «Центр гигиены и эпидемиологии в Запорожской области»',
				},
			],
		});
	},
};
</script>
