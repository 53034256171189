<template>
  <NavigationComp :isDisable="false" />
  <main class="main">
    <div class="main__wrapper container">
      <h2 class="main__wrapper-title">Прочие</h2>
      <DocumentListComp :documentsListName="'documentsList'" />
    </div>
  </main>
</template>

<script>
import NavigationComp from "@/components/UI/Navigation/NavigationComp.vue";
import DocumentListComp from "@/components/UI/Documents/DocumentListComp.vue";
import { useHead } from "@vueuse/head";

export default {
  components: { NavigationComp, DocumentListComp },
	name: 'Документы',

  setup() {
    useHead({
      title:
        "Документы | Федеральное бюджетное учреждение здравоохранения «Центр гигиены и эпидемиологии в Запорожской области»",
      meta: [
        {
          name: "documents",
          content:
            "Федеральное бюджетное учреждение здравоохранения «Центр гигиены и эпидемиологии в Запорожской области»",
        },
      ],
    });
  },
};
</script>
