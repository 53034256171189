<template>
	<NavigationComp :isDisable="false" />
	<main class="labCenter container">
		<h1 class="labCenter__title">Лабораторный центр</h1>
		<p class="labCenter__text">
			На базе ФБУЗ «Центр гигиены и эпидемиологии в Запорожской области» функционирует
			<span class="labCenter__text--bold">испытательный лабораторный центр</span>
			(далее - ИЛЦ).
		</p>

		<p class="labCenter__text">
			На базе лабораторий, входящих в состав ИЛЦ, проводится широкий спектр микробиологических, паразитологических,
			санитарно-гигиенических, радиологических, токсикологических, вирусологических исследований, а также измерений
			физических факторов.
		</p>

		<ul class="labCenter__ulList">
			<h4 class="labCenter__listTitle">Основными объектами исследований являются:</h4>
			<li>- вода питьевая, вода бассейнов, вода открытых водоёмов, морская вода, сточная вода;</li>
			<li>- почва (в т.ч. песок);</li>
			<li>- атмосферный воздух;</li>
			<li>- воздух рабочей зоны, воздух помещений;</li>
			<li>- пищевые продукты;</li>
			<li>- биоматериал.</li>
		</ul>

		<ol class="labCenter__olList">
			<h4 class="labCenter__listTitle">В состав ИЛЦ входят:</h4>
			<li class="labCenter__olList--marker">
				Лаборатория санитарно-гигиенических исследований и контроля за ионизирующими и неионизирующими источниками
				излучения (ЛСГИКИНИИ):
			</li>
			<ul class="labCenter__ulList">
				<li class="labCenter__ulList--padding">- отделение санитарно-гигиенических исследований;</li>
				<li class="labCenter__ulList--padding">
					- отделение по контролю за ионизирующими и неионизирующими источниками излучений
				</li>
			</ul>
			<li class="labCenter__olList--marker">Микробиологическая лаборатория (МБЛ):</li>
			<ul class="labCenter__ulList">
				<li class="labCenter__ulList--padding">
					- отделение бактериологических исследований и особо опасных инфекций;
				</li>
				<li class="labCenter__ulList--padding">- отделение молекулярно-генетических исследований;</li>
				<li class="labCenter__ulList--padding">- отделение серологических исследований и классической вирусологии;</li>
				<li class="labCenter__ulList--padding">- отделение паразитологических исследований;</li>
			</ul>
		</ol>
		<ul class="labCenter__ulList">
			<h4 class="labCenter__listTitle">Основные функции ИЛЦ:</h4>
			<li class="labCenter__ulList--marker">
				проведение исследований в соответствии с государственным заданием для обеспечения деятельности Управления
				Роспотребнадзора по Запорожской области;
			</li>
			<li class="labCenter__ulList--marker">
				проведение исследований пищевых продуктов, продовольственного сырья, объектов окружающей среды (воздуха, воды,
				почвы), биоматериала по договорам возмездного характера с гражданами, индивидуальными предпринимателями и
				юридическими лицами с целью установления их соответствия требованиям законодательных, нормативных, технических
				документов и в рамках производственного контроля.
			</li>
		</ul>

		<h2 class="labCenter__chapterTitle">Отделение санитарно-гигиенических исследований ЛСГИКИНИИ</h2>
		<h3 class="labCenter__chapterSubtitle">На базе отделения СГИ ЛСГИКИНИИ проводятся исследования:</h3>

		<ol class="labCenter__olList">
			<li class="labCenter__olList--marker">
				Определение органолептических и физико-химических показателей качества во всех видах продовольственного сырья и
				пищевых продуктов, в том числе:
			</li>
			<ul class="labCenter__ulList">
				<li class="labCenter__ulList--marker labCenter__olList--padding">полуфабрикаты;</li>
				<li class="labCenter__ulList--marker labCenter__olList--padding">кулинарные и кондитерские изделия;</li>
				<li class="labCenter__ulList--marker labCenter__olList--padding">хлеб и хлебобулочные изделия;</li>
				<li class="labCenter__ulList--marker labCenter__olList--padding">молоко и молочные продукты;</li>
				<li class="labCenter__ulList--marker labCenter__olList--padding">мясо и мясные продукты;</li>
				<li class="labCenter__ulList--marker labCenter__olList--padding">рыба и рыбные продукты;</li>
				<li class="labCenter__ulList--marker labCenter__olList--padding">плоды и овощи;</li>
				<li class="labCenter__ulList--marker labCenter__olList--padding">напитки, соки</li>
			</ul>
			<li class="labCenter__olList--marker">
				Определение качества воды (питьевая централизованных и нецентрализованных систем водоснабжения, бассейнов,
				открытых водоемов, техническая, дистиллированная, бутилированная вода);
			</li>
			<li class="labCenter__olList--marker">Определение физико-химического состава почв;</li>
			<li class="labCenter__olList--marker">Определение содержания активных веществ в дезинфекционных средствах</li>
			<li class="labCenter__olList--marker">
				Проведение исследований воздуха атмосферного и воздуха рабочей зоны на предприятиях различных отраслей.
			</li>
		</ol>

		<h2 class="labCenter__chapterTitle">
			Отделение по контролю за ионизирующими и неионизирующими источниками излучений ЛСГИКИНИИ
		</h2>

		<p class="labCenter__text">
			На базе отделения КИНИ ЛСГИКИНИИ проводится широкий спектр радиологических исследований и измерений физических
			факторов.
		</p>

		<table class="labCenter__table labCenter__table--border">
			<thead>
				<tr>
					<th class="labCenter__table-title labCenter__table--border">Измерения физических факторов</th>
					<th class="labCenter__table-title labCenter__table--border">Радиологические исследования</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td class="labCenter__table--border">Шум-постоянный, непостоянный, импульсный</td>
					<td class="labCenter__table--border">Измерения мощности дозы гамма, рентгеновского излучения</td>
				</tr>
				<tr>
					<td class="labCenter__table--border">Инфразвук</td>
					<td class="labCenter__table--border">Инфразвук Контроль загрязнённости радиоактивными веществами</td>
				</tr>
				<tr>
					<td class="labCenter__table--border">Ультразвук</td>
					<td class="labCenter__table--border">
						Индивидуальный дозиметрический контроль персонала с помощью индивидуальных термолюминесцентных дозиметров
					</td>
				</tr>
				<tr>
					<td class="labCenter__table--border">Вибрация (общая и локальная)</td>
					<td class="labCenter__table--border">Радиационный контроль по программе производственного контроля</td>
				</tr>
				<tr>
					<td class="labCenter__table--border">
						Параметры световой среды- освещенность, яркость, коэффициент пульсации
					</td>
					<td class="labCenter__table--border">
						Радиационный контроль жилых помещений, общественных и производственных зданий, включая измерения мощности
						дозы (МАЭД) гамма-излучения, измерения эквивалентной равновесной объёмной активности (ЭРОА) изотопов радона
						и торона в воздухе помещений
					</td>
				</tr>
				<tr>
					<td class="labCenter__table--border">
						Параметры микроклимата помещений- температура воздуха, температура поверхностей, относительная влажность
						воздуха, скорость движения воздуха, интенсивность теплового облучения
					</td>
					<td class="labCenter__table--border">
						Лабораторные исследования (спектрометрические, радиометрические) пищевых продуктов на содержание цезия и
						стронция
					</td>
				</tr>
				<tr>
					<td class="labCenter__table--border">Аэроионный состав воздуха в помещениях</td>
					<td class="labCenter__table--border">
						Исследование радионуклидного состава и удельной активности естественных и искусственных радионуклидов в
						объектах окружающей среды, строительных и отделочных материалах
					</td>
				</tr>
				<tr>
					<td class="labCenter__table--border">
						Электромагнитный поля от средств информационно-коммуникационных технологий, в том числе от ПК на рабочих
						местах
					</td>
					<td class="labCenter__table--border"></td>
				</tr>
				<tr>
					<td class="labCenter__table--border">
						Электромагнитные поля переменного тока промышленной частоты (50 ГЦ), в том числе от линий электропередач
					</td>
					<td class="labCenter__table--border"></td>
				</tr>
				<tr>
					<td class="labCenter__table--border">
						Электромагнитные излучения на рабочих местах в физиотерапевтических кабинетах
					</td>
					<td class="labCenter__table--border"></td>
				</tr>
				<tr>
					<td class="labCenter__table--border">
						Постоянное магнитное поле в помещениях и на рабочих местах от оборудования, в том числе от
						магнитно-резонансного томографа
					</td>
					<td class="labCenter__table--border"></td>
				</tr>
				<tr>
					<td class="labCenter__table--border">Электростатические поля в помещениях и на производстве</td>
					<td class="labCenter__table--border"></td>
				</tr>
				<tr>
					<td class="labCenter__table--border">Электростатические поля от товаров народного потребления</td>
					<td class="labCenter__table--border"></td>
				</tr>
			</tbody>
		</table>

		<h2 class="labCenter__chapterTitle">
			Отделение бактериологических исследований и особо опасных инфекций микробиологической лаборатории.
		</h2>
		<p class="labCenter__text labCenter__text--bold">
			В отделении бактериологических исследований и особо опасных инфекций (ОБИООИ) микробиологической лаборатории
			проводятся бактериологические исследования:
		</p>
		<ul class="labCenter__ulList">
			<li class="labCenter__ulList--marker">
				<span class="labCenter__text--bold">пищи:</span> продовольственное сырьё, пищевые продукты, питьевая вода,
				расфасованная в ёмкости
			</li>
			<li class="labCenter__ulList--marker">
				<span class="labCenter__text--bold">объектов окружающей среды:</span> вода питьевая централизованного и
				нецентрализованного (в том числе дачные колодцы, скважины) водоснабжения, бассейны, водоёмы, сточная вода,
				почва, воздух помещений (лечебные учреждения), смывы (лечебные учреждения и пищевые объекты), контроль
				эффективности работы стерилизующей аппаратуры
			</li>
			<li class="labCenter__ulList--marker">
				<span class="labCenter__text--bold"
					>бактериологические исследования клинического (биологического материала), в том числе для устройства на
					работу:</span
				>
				дифтерия, коклюш и паракоклюш, золотистый стафилококк, на микрофлору и чувствительность к антибиотикам, кишечный
				дисбактериоз, кал на патогенные энтеробактерии, кровь на брюшной тиф.
			</li>
			<li class="labCenter__ulList--marker">
				биологический материал от людей и материал из внешней среды на особо опасные инфекции (холера, НАГ вибрионы,
				иерсинии)
			</li>
		</ul>

		<h2 class="labCenter__chapterTitle">Отделение молекулярно-генетических исследований.</h2>
		<p class="labCenter__text">
			<span class="labCenter__text--bold"
				>Отделение молекулярно-генетических исследований микробиологической лаборатории</span
			>
			проводит исследования различных видов клинического материала на обнаружение возбудителей инфекционных заболеваний
			и напряженность иммунитета к вирусным инфекциям:
		</p>
		<ul class="labCenter__ulList">
			<li class="labCenter__ulList--marker">вирусов гриппа и ОРВИ, микоплазмы и хламидии пневмонии;</li>
			<li class="labCenter__ulList--marker">ротавирусов, норовирусов, астровирусов, энтеровирусов;</li>
			<li class="labCenter__ulList--marker">
				напряженность иммунитета к кори, краснухе, эпидемическому паротиту, гепатиту В, полиомиелиту.
			</li>
		</ul>

		<h2 class="labCenter__chapterTitle">Отделение паразитологических исследований микробиологической лаборатории.</h2>
		<table class="labCenter__table">
			<tbody>
				<tr>
					<td class="labCenter__table--width labCenter__text--bold">
						Лабораторная диагностика гельминтозов и протозоозов
					</td>
					<td>
						Определение вида гельминтов (аскариды, острицы, власоглав, свиной и бычий цепень, широкий лентец, описторх и
						т.д.) и патогенных простейших кишечника (лямблии, балантидии, дизентерийная амеба, бластоцисты, крипто
						споридии, пневмоцисты) в биоматериале:
						<ul class="labCenter__ulList">
							<li class="labCenter__ulList--marker">фекалии;</li>
							<li class="labCenter__ulList--marker">перианальный отпечаток;</li>
							<li class="labCenter__ulList--marker">желчь;</li>
							<li class="labCenter__ulList--marker">мокрота;</li>
							<li class="labCenter__ulList--marker">кровь.</li>
						</ul>
					</td>
				</tr>
				<tr>
					<td class="labCenter__table--width labCenter__text--bold">Санитарно-паразитологические исследования</td>
					<td>
						Определение возбудителей гельминтозов и протозоозов:

						<ul class="labCenter__ulList">
							<li class="labCenter__ulList--marker">пищевые продукты животного происхождения (мясо, рыба);</li>
							<li class="labCenter__ulList--marker">
								пищевые продукты растительного происхождения (овощи, фрукты, ягоды, зелень);
							</li>
							<li class="labCenter__ulList--marker">питьевая воды, расфасованная в ёмкости.</li>
						</ul>

						Определение зараженности яйцами гельминтов и патогенных кишечных простейших:
						<ul class="labCenter__ulList">
							<li class="labCenter__ulList--marker">почва, песок;</li>
							<li class="labCenter__ulList--marker">сточная вода, иловые осадки;</li>
							<li class="labCenter__ulList--marker">вода водоемов;</li>
							<li class="labCenter__ulList--marker">вода плавательных бассейнов;</li>
							<li class="labCenter__ulList--marker">питьевая вода централизованного водоснабжения;</li>
							<li class="labCenter__ulList--marker">смывы с объектов окружающей среды.</li>
						</ul>
					</td>
				</tr>
				<tr>
					<td class="labCenter__table--width labCenter__text--bold">Энтомологические исследования</td>
					<td>
						Определение зараженности и загрязненности вредителями хлебных запасов в пищевых продуктах:
						<ul class="labCenter__ulList">
							<li class="labCenter__ulList--marker">сухофрукты,</li>
							<li class="labCenter__ulList--marker">орехи,</li>
							<li class="labCenter__ulList--marker">зернобобовые,</li>
							<li class="labCenter__ulList--marker">мука,</li>
							<li class="labCenter__ulList--marker">крупы.</li>
						</ul>
						Определение личинок и куколок синантропных мух:
						<ul class="labCenter__ulList">
							<li class="labCenter__ulList--marker">почва, песок.</li>
						</ul>
						Определение видовой принадлежности насекомых и клещей в образцах (пробах) <br />
						Исследование на наличие насекомых и клещей:
						<ul class="labCenter__ulList">
							<li class="labCenter__ulList--marker">пух-перо, мех</li>
							<li class="labCenter__ulList--marker">шерсть,</li>
							<li class="labCenter__ulList--marker">другие натуральные материалы.</li>
						</ul>
					</td>
				</tr>
				<tr>
					<td class="labCenter__table--width labCenter__text--bold">Энтомологические обследования</td>
					<td>
						<ul class="labCenter__ulList">
							<li class="labCenter__ulList--marker">
								Жилых и общественных зданий и сооружений на наличие членистоногих.
							</li>
							<li class="labCenter__ulList--marker">
								Подвальных помещений и открытых водоёмов на наличие личинок и куколок кровососущих комаров.
							</li>
							<li class="labCenter__ulList--marker">
								Территорий парков и других объектов на наличие иксодовых клещей.
							</li>
							<li class="labCenter__ulList--marker">Оценка эффективности проведенных дезинсекционных мероприятий.</li>
						</ul>
					</td>
				</tr>
			</tbody>
		</table>
	</main>
</template>

<script>
import NavigationComp from '@/components/UI/Navigation/NavigationComp.vue';
import { useHead } from '@vueuse/head';

export default {
	components: { NavigationComp },
	name: 'Лабораторный центр',

	setup() {
		useHead({
			title:
				'Лабораторный центр | Федеральное бюджетное учреждение здравоохранения «Центр гигиены и эпидемиологии в Запорожской области»',
			meta: [
				{
					name: 'description',
					content:
						'На базе ФБУЗ «Центр гигиены и эпидемиологии в Запорожской области» функционирует испытательный лабораторный центр (далее - ИЛЦ).',
				},
			],
		});
	},
};
</script>

<style lang="scss" scoped></style>
