const ContactsList = {
	state: () => ({
		contactsList: [
			{
				id: 1,
				title: 'Запорожская область, г.Мелитополь, ул.Сухова, д.6',
				tel: '+7-990-290-36-38 – Приёмная главного врача ',
				tel2: '+7-990-234-50-40 – Горячая линия',
				tel3: '+7-990-290-36-39 – Отдел кадров',
				tel4: '+7-990-290-36-40 – Главный бухгалтер',
				email: 'fbuz85@cgezo.ru',
				// email2: 'fbuz_85@rospotrebnadzor.ru',
			},
			{
				id: 2,
				title: 'Запорожская область, Мелитопольский район, пгт. Акимовка ул. Театральная д.40',
				tel: '+7-990-234-50-40',
				email: 'fbuz85@cgezo.ru',
			},
			{
				id: 3,
				title: 'Запорожская область, Мелитопольский район, пгт. Приазовское ул. Покровская д.3 ',
				tel: '+7-990-234-50-40',
				email: 'fbuz85@cgezo.ru',
			},
		],
	}),
	rootGetters: {},
	mutations: {},
	actions: {},
};

export default ContactsList;
