import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { createHead } from '@vueuse/head';
import { createYmaps } from 'vue-yandex-maps';
import type { VueYandexMaps } from 'vue-yandex-maps';

import '@/assets/scss/main.scss';

const settings: VueYandexMaps.PluginSettings = {
  apikey: '373a66c6-62f6-44fd-a381-8312e2465fbc',
};

createApp(App).use(store).use(router).use(createHead()).use(createYmaps(settings)).mount('#app');
