<template>
  <NavigationComp :isDisable="false" />
  <main class="price container">
    <h1 class="price__title">Прайс-лист</h1>
    <DocumentListComp :documentsListName="'priceDocuments'" />
    <div class="price__table-wrapper">
      <table class="price__table">
        <tbody>
          <tr>
            <th style="width: 10%">№п/п</th>
            <th>НАИМЕНОВАНИЕ РАБОТ (УСЛУГ)</th>
            <th style="width: 20%">Ед. измерения</th>
            <th style="width: 15%">Цена без НДС (руб.)</th>
            <th style="width: 15%">Цена с НДС (руб.)</th>
          </tr>
        </tbody>
      </table>
      <PriceTableComp />
    </div>
  </main>
</template>

<script>
import NavigationComp from "@/components/UI/Navigation/NavigationComp.vue";
import DocumentListComp from "@/components/UI/Documents/DocumentListComp.vue";

import { useHead } from "@vueuse/head";
import PriceTableComp from "@/components/UI/PriceTableComp.vue";

export default {
  components: { NavigationComp, DocumentListComp, PriceTableComp },
  name: "Прайс-лист",
  setup() {
    useHead({
      title:
        "Прайс | Федеральное бюджетное учреждение здравоохранения «Центр гигиены и эпидемиологии в Запорожской области»",
      meta: [
        {
          name: "description",
          content:
            "Прайс-лист Центра гигиены и эпидемиологии в Запорожской области.",
        },
      ],
    });
  },
};
</script>

<style lang="scss" scoped></style>
