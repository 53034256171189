<template>
  <div class="home-navigation__rightSide">
    <router-link
      class="home-navigation__rightSide-link"
      v-for="rightSideLink in rightSideLinks"
      :key="rightSideLink.id"
      :to="rightSideLink.link"
    >
      {{ rightSideLink.title }}
    </router-link>
    <router-link
      class="education-start__btn"
      :to="mainLink.url"
      :key="mainLink.alias"
    >
      <span v-html="mainLink.title"></span>
      <img src="@/assets/img/symbols/logo.svg" alt="logo" />
    </router-link>
    <router-link class="home-navigation__rightSide-link" to="/documents">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
        <!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
        <path
          d="M64 464c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16H224v80c0 17.7 14.3 32 32 32h80V448c0 8.8-7.2 16-16 16H64zM64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V154.5c0-17-6.7-33.3-18.7-45.3L274.7 18.7C262.7 6.7 246.5 0 229.5 0H64zm56 256c-13.3 0-24 10.7-24 24s10.7 24 24 24H264c13.3 0 24-10.7 24-24s-10.7-24-24-24H120zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H264c13.3 0 24-10.7 24-24s-10.7-24-24-24H120z"
        />
      </svg>
      ФОРМЫ ЗАЯВЛЕНИЙ
    </router-link>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Правое меню домашней страницы",
  computed: {
    ...mapState({
      mainLink: (state) => state.HomeMenuStore.mainLink,

      rightSideLinks: (state) => state.HomeMenuStore.rightSideLinks,
    }),
  },
};
</script>

<style scoped></style>
