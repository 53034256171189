<template>
  <div class="map__markers">
    <h2 class="map__markers-title">{{ filial.title }}</h2>
    <div class="map__markers-contacts">
      <a href="#">
        <img
          src="@/assets/img/icons/contact/icon-map-contact-addr.svg"
          alt="Адрес"
        />
        {{ filial.address }}
      </a>
      <a :href="filial.tel">
        <img
          src="@/assets/img/icons/contact/icon-map-phone.svg"
          alt="Телефон"
        />
        {{ filial.tel }}
      </a>
      <a href="https://www.cgezo.ru/" target="_blank">
        <img
          src="@/assets/img/icons/contact/icon-map-contact-addr.svg"
          alt="Сайт"
        />
        https://www.cgezo.ru/
      </a>
      <a v-if="filial.email" href="mailto:test@test.ru">
        <img src="@/assets/img/icons/contact/icon-map-mail.svg" alt="Почта" />
        {{ filial.email }}
      </a>
      <a v-if="filial.email2" href="mailto:test@test.ru">
        <img src="@/assets/img/icons/contact/icon-map-mail.svg" alt="Почта" />
        {{ filial.email2 }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filial: Object,
  },
};
</script>

<style lang="scss" scoped></style>
