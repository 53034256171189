<template>
  <div class="home-navigation__leftSide">
    <div
      class="home-navigation__leftSide-linksList home-navigation__leftSide-linksList_firstProportions"
    >
      <a
        class="home-navigation__leftSide-first"
        href="https://85.rospotrebnadzor.ru/"
      >
        <img class="logo" src="@/assets/img/symbols/logo.svg" alt="Logo" />
        Управление Роспотребнадзора по Запорожской области
      </a>
    </div>
    <div
      class="home-navigation__leftSide-linksList home-navigation__leftSide-linksList_secondProportions"
    >
      <div v-for="link in homePageMenu" :key="link.alias">
        <router-link
          class="home-navigation__leftSide-linksList__main-link"
          :to="link.url"
          @mouseover="openMenu(link.alias)"
        >
          {{ link.title }}
        </router-link>
        <div
          class="home-navigation__leftSide-linksList__submenu"
          v-if="link.alias === 'about'"
          :class="{ visibleSubMenu: visibleSubMenu === 'about' }"
        >
          <router-link
            class="home-navigation__leftSide-linksList__submenu-link"
            to="./documents"
          >
            Документы
          </router-link>
          <router-link
            class="home-navigation__leftSide-linksList__submenu-link"
            to="./anti-corruption"
          >
            Противодействие коррупции
          </router-link>
        </div>
        <div
          class="home-navigation__leftSide-linksList__submenu"
          v-if="link.alias === 'structure'"
          :class="{ visibleSubMenu: visibleSubMenu === 'structure' }"
        >
          <a
            class="home-navigation__leftSide-linksList__submenu-link"
            v-for="structureLink in structureLinks"
            :key="structureLink.id"
            :href="structureLink.file"
            target="_blank"
          >
            {{ structureLink.title }}
          </a>
        </div>
        <div
          class="home-navigation__leftSide-linksList__submenu"
          v-if="link.alias === 'services'"
          :class="{ visibleSubMenu: visibleSubMenu === 'services' }"
        >
          <router-link
            class="home-navigation__leftSide-linksList__submenu-link"
            to="./price"
            >Прайс</router-link
          >
        </div>
        <div
          class="home-navigation__leftSide-linksList__submenu"
          v-if="link.alias === 'services'"
          :class="{ visibleSubMenu: visibleSubMenu === 'services' }"
        >
          <router-link
            class="home-navigation__leftSide-linksList__submenu-link"
            to="./services-for-people"
          >
            Услуги для населения
          </router-link>
        </div>
        <div
          class="home-navigation__leftSide-linksList__submenu"
          v-if="link.alias === 'lab'"
          :class="{ visibleSubMenu: visibleSubMenu === 'lab' }"
        >
          <router-link
            class="home-navigation__leftSide-linksList__submenu-link"
            to="./structure-ilc"
          >
            Структура ИЛЦ
          </router-link>
        </div>
        <div
          class="home-navigation__leftSide-linksList__submenu"
          v-if="link.alias === 'contacts'"
          :class="{ visibleSubMenu: visibleSubMenu === 'contacts' }"
        >
          <router-link
            class="home-navigation__leftSide-linksList__submenu-link"
            to="./phonebook"
          >
            Телефонный справочник
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Левое меню домашней страницы",
  computed: {
    ...mapState({
      homePageMenu: (state) => state.HomeMenuStore.homePageMenu,
      visibleSubMenu: (state) => state.HomeMenuStore.visibleSubMenu,
      structureLinks: (state) => state.DocumentsStore.structureDocuments,
    }),
  },
  methods: {
    ...mapActions(["openMenu"]),
  },
};
</script>

<style scoped></style>
