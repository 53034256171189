<template>
  <NavigationComp :isDisable="false" />
  <main class="vacancy container">
    <h1 class="vacancy__title">Вакансии</h1>
    <div class="vacancy__table-wrapper">
      <table class="vacancy__table">
        <tbody>
          <tr>
            <th>Должность, отдел (отделение)</th>
            <th style="width: 15%">Заработная плата</th>
          </tr>
        </tbody>
      </table>
      <VacancyComp />
    </div>
  </main>
</template>

<script>
import NavigationComp from "@/components/UI/Navigation/NavigationComp.vue";

import { useHead } from "@vueuse/head";
import VacancyComp from "@/components/UI/VacancyComp.vue";

export default {
  components: { NavigationComp, VacancyComp },
  name: "Вакансии",
  data() {
    return {};
  },

  setup() {
    useHead({
      title:
        "Вакансии | Федеральное бюджетное учреждение здравоохранения «Центр гигиены и эпидемиологии в Запорожской области»",
      meta: [
        {
          name: "description",
          content:
            "Вакансии Центра гигиены и эпидемиологии в Запорожской области.",
        },
      ],
    });
  },
};
</script>

<style lang="scss" scoped></style>
