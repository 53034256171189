<template>
  <div class="main__document__wrapper">
    <DocumentComp
      v-for="document in getDocumentsList(documentsListName)"
      :key="document.id"
      :document="document"
      :icon="getIconFormat(document)"
    />
  </div>
</template>

<script>
import DocumentComp from "@/components/UI/Documents/DocumentComp.vue";
import { mapState } from "vuex";

export default {
  name: "Список документов",
  props: {
    documentsListName: String,
  },
  components: { DocumentComp },
  computed: {
    ...mapState({
      iconsList: (state) => state.DocumentsStore.iconsList,
      documentsList: (state) => state.DocumentsStore.documentsList,
      servicesDocuments: (state) => state.DocumentsStore.servicesDocuments,
      priceDocuments: (state) => state.DocumentsStore.priceDocuments,
      antiCorruptionDocuments: (state) =>
        state.DocumentsStore.antiCorruptionDocuments,
      structureDocuments: (state) => state.DocumentsStore.structureDocuments,
    }),
  },
  methods: {
    getIconFormat(document) {
      switch (document.format) {
        case "pdf":
          return this.iconsList[0];
        case "docx":
          return this.iconsList[1];
        case "xlsx":
          return this.iconsList[2];
        default:
          break;
      }
    },
    getDocumentsList(listName) {
      switch (listName) {
        case "documentsList":
          return this.documentsList;
        case "servicesDocuments":
          return this.servicesDocuments;
        case "priceDocuments":
          return this.priceDocuments;
        case "antiCorruptionDocuments":
          return this.antiCorruptionDocuments;
        case "structureDocuments":
          return this.structureDocuments;
        default:
          break;
      }
    },
  },
};
</script>

<style scoped></style>
