<template>
  <NavigationComp :isDisable="false" />
  <div class="notFound__wrapper container">
    <div class="notFound__block">
      <h1>Такой страницы не существует</h1>
      <router-link to="/">Вернуться на главную</router-link>
    </div>
    <img src="@/assets/img/404.png" alt="404" />
  </div>
</template>

<script>
import NavigationComp from "@/components/UI/Navigation/NavigationComp.vue";
import { useHead } from "@vueuse/head";

export default {
  components: { NavigationComp },
  name: "404",
  setup() {
    useHead({
      title:
        "Страница не найдена | Федеральное бюджетное учреждение здравоохранения «Центр гигиены и эпидемиологии в Запорожской области»",
      meta: [
        {
          name: "description",
          content: "404 - Страница не найдена",
        },
      ],
    });
  },
};
</script>
