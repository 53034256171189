<template>
  <div class="balloon">
    <h2>{{ filial.title }}</h2>
    <h3>{{ filial.address }}</h3>
    <a :href="filial.tel">{{ filial.tel }}</a>
    <a :href="filial.email">{{ filial.email }}</a>
    <a :href="filial.email2">{{ filial.email2 }}</a>
    <img :src="filial.image" alt="Фото филиала" />
  </div>
</template>

<script>
export default {
  props: {
    filial: Object,
  },
};
</script>

<style lang="scss" scoped>
.balloon {
  display: flex;
  flex-direction: column;
  gap: 7px;
  background-color: #fff;
  padding: 10px;
  min-width: 250px;
}
</style>
