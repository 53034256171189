import REQUISITES from '@/assets/documents/Реквизиты для оплаты.docx';
import EXTRACTFBUZMELITOPOL from '@/assets/documents/ВЫПИСКА ФБУЗ г.Мелитополь.pdf';
import ApplicationForTickResearch from '@/assets/documents/Заявление на исследование клеща.docx';
import OFORMLENIELMK from '@/assets/documents/Заявление на оформление ЛМК.docx';
import ZAYAVLENIELKMINDIVIDUALNO from '@/assets/documents/Заявление на ЛМК - индивидуально (фл).docx';
import DOVERENNOST from '@/assets/documents/Доверенность.docx';
import ZAYAVLENIE_FL_DEZ from '@/assets/documents/ЗАЯВЛЕНИЕ ФЛ ДЕЗ.docx';
import ZAYAVLENIE_YL_DEZ from '@/assets/documents/ЗАЯВЛЕНИЕ ЮЛ ДЕЗ.docx';
import ZAYAVLENIE_NA_LMK_YL from '@/assets/documents/ЗАЯВЛЕНИЕ на ЛМК ЮЛ НА КОЛЛЕКТИВ.docx';

/* Противодействие коррупции */
import SOSTAV2 from '@/assets/documents/corruption/Состав_комиссии_по_соблюдению_требований_к_служебному_поведению.pdf';
import PLAN from '@/assets/documents/corruption/План работы комиссии.pdf';

import PRICELIST from '@/assets/documents/Прейскурант ФБУЗ ЗО.xlsx';
import PRICELISTPDF from '@/assets/documents/Прейскурант ФБУЗ ЗО.pdf';

import MELITOPOLPDF from '@/assets/documents/СТРУКТУРА ФБУЗ г.Мелитополь.pdf';
import BERDIANSKPDF from '@/assets/documents/СТРУКТУРА ФБУЗ г.Бердянск.pdf';
import POLOGIPDF from '@/assets/documents/СТРУКТУРА ФБУЗ г.Пологи.pdf';

const DocumentsStore = {
  state: () => ({
    iconsList: [
      {
        id: 1,
        url: require('@/assets/img/icons/documents/pdf.svg'),
      },
      {
        id: 2,
        url: require('@/assets/img/icons/documents/doc.svg'),
      },
      {
        id: 3,
        url: require('@/assets/img/icons/documents/xls.svg'),
      },
    ],
    documentsList: [
      {
        id: 1,
        format: 'docx',
        title: 'Заявление на ЛМК - индивидуально (ф/л)',
        file: ZAYAVLENIELKMINDIVIDUALNO,
        size: '30.3 Кб',
      },
      {
        id: 2,
        format: 'docx',
        title: 'Реквизиты для оплаты',
        file: REQUISITES,
        size: '14.1 Кб',
      },
      {
        id: 3,
        format: 'docx',
        title: 'Доверенность',
        file: DOVERENNOST,
        size: '15.8 Кб',
      },
      {
        id: 4,
        format: 'pdf',
        title: 'ВЫПИСКА ФБУЗ г.Мелитополь',
        file: EXTRACTFBUZMELITOPOL,
        size: '163.0 Кб',
      },
      {
        id: 5,
        format: 'docx',
        title: 'Заявление на проведение лабораторных исследований клеща',
        file: ApplicationForTickResearch,
        size: '26.3 Кб',
      },
      {
        id: 6,
        format: 'docx',
        title: 'Заявление на оформление ЛМК',
        file: OFORMLENIELMK,
        size: '26.2 Кб',
      },
      {
        id: 7,
        format: 'docx',
        title: 'Заявление на оформление услуг ЛМК (ю/л на коллектив)',
        file: ZAYAVLENIE_NA_LMK_YL,
        size: '30.0 Кб',
      },
      {
        id: 8,
        format: 'docx',
        title: 'Заявление ФЛ дезработы',
        file: ZAYAVLENIE_FL_DEZ,
        size: '22.5 Кб',
      },
      {
        id: 9,
        format: 'docx',
        title: 'Заявление ЮЛ дезработы',
        file: ZAYAVLENIE_YL_DEZ,
        size: '22.1 Кб',
      },
    ],
    servicesDocuments: [
      {
        id: 1,
        format: 'xlsx',
        title: 'Прейскурант ФБУЗ ЗО',
        file: PRICELIST,
        size: '112 Кб',
      },
      {
        id: 2,
        format: 'docx',
        title: 'Реквизиты для оплаты',
        file: REQUISITES,
        size: '14.1 Кб',
      },
    ],
    priceDocuments: [
      {
        id: 1,
        format: 'xlsx',
        title: 'Прейскурант ФБУЗ ЗО',
        file: PRICELIST,
        size: '112 Кб',
      },
      {
        id: 2,
        format: 'pdf',
        title: 'Прейскурант ФБУЗ ЗО',
        file: PRICELISTPDF,
        size: '41.8 Мб',
      },
    ],
    antiCorruptionDocuments: [
      {
        id: 1,
        format: 'pdf',
        title: 'Состав комиссии по соблюдению требований к служебному поведению',
        file: SOSTAV2,
        size: '458.0 Кб',
      },
      {
        id: 2,
        format: 'pdf',
        title: 'План работы комиссии',
        file: PLAN,
        size: '6.67 Мб',
      },
    ],
    structureDocuments: [
      {
        id: 1,
        format: 'pdf',
        title: 'Структура ФБУЗ г. Мелитополь',
        file: MELITOPOLPDF,
        size: '197.0 Кб',
      },
      {
        id: 2,
        format: 'pdf',
        title: 'Структура ФБУЗ г. Бердянск',
        file: BERDIANSKPDF,
        size: '129.0 Кб',
      },
      {
        id: 3,
        format: 'pdf',
        title: 'Структура ФБУЗ г. Пологи',
        file: POLOGIPDF,
        size: '438.0 Кб',
      },
    ],
  }),
  rootGetters: {},
  mutations: {},
  actions: {},
};

export default DocumentsStore;
