import { createStore } from "vuex";
import NavigationStore from "./modules/NavigationStore";
import FilialsStore from "./modules/FilialsStore";
import ContactsList from "./modules/ContactsList";
import HomeMenuStore from "./modules/HomeMenuStore";
import NewsStore from "./modules/NewsStore";
import PriceTable from "./modules/PriceTable";
import Vacancy from "./modules/Vacancy";
import DocumentsStore from "./modules/DocumentsStore";
import RequisitesStore from "./modules/RequisitesStore";
import ServicesStore from "./modules/ServicesStore";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    NavigationStore,
    FilialsStore,
    ContactsList,
    HomeMenuStore,
    NewsStore,
    PriceTable,
    Vacancy,
    DocumentsStore,
    RequisitesStore,
    ServicesStore,
  },
});
