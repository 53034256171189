<template>
  <header class="header">
    <img
      id="specialButton"
      src="@/assets/img/icons/vision.png"
      alt="ВЕРСИЯ ДЛЯ СЛАБОВИДЯЩИХ"
      title="ВЕРСИЯ ДЛЯ СЛАБОВИДЯЩИХ"
    />
    <div class="header-title container">
      <div class="header-title__leftSide">
        <div class="header-title__logo-block">
          <router-link :to="'./'">
            <img class="logo" src="@/assets/img/symbols/logo.svg" alt="Logo" />
          </router-link>
          <span class="header-title__logo-description"
            >272309, Запорожская область, г.&nbsp;Мелитополь, ул. Сухова, д.
            6</span
          >
        </div>
        <div class="header-title__title-block">
          <h1 class="header-title__title-block__subtitle">
            ФЕДЕРАЛЬНАЯ СЛУЖБА ПО НАДЗОРУ В СФЕРЕ ЗАЩИТЫ ПРАВ ПОТРЕБИТЕЛЕЙ И
            БЛАГОПОЛУЧИЯ ЧЕЛОВЕКА
          </h1>
          <h2 class="header-title__title-block__title">
            {{ title }}
          </h2>
        </div>
        <img
          class="header-title__flag"
          src="@/assets/img/symbols/flag.png"
          alt="flag"
        />
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Шапка сайта",

  data() {
    return {
      title:
        "Федеральное бюджетное учреждение здравоохранения «Центр гигиены и эпидемиологии в Запорожской области»",
    };
  },
};
</script>

<style lang="scss"></style>
