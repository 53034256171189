const FilialsStore = {
  state: () => ({
    filialListFullInfo: [
      {
        id: 1,
        title: 'Филиал ФБУЗ «ЦГиЭ» в г.Бердянск, Бердянском, Черниговском и Приморском районах',
        address: '271100, Запорожская область, г. Бердянск, ул. Свободы, д. 115',
        image: 'http://via.placeholder.com/350x150',
        coordinates: [46.758643, 36.798345],
        tel: '+7-990-290-36-42',
        // email: 'fbuz_85@rospotrebnadzor.ru',
        email2: 'brd_fbuz85@cgezo.ru',
      },
      {
        id: 2,
        title: 'Филиал ФБУЗ «ЦГиЭ в ЗО» в Пологовском , Ореховском, Гуляйпольском, и Токмакском районе',
        address: 'Запорожская область, г. Токмак, ул. Гоголя, д. 44',
        image: 'http://via.placeholder.com/350x150',
        coordinates: [47.255104, 35.709187],
        tel: '+7-990-290-36-41',
        // email: 'fbuz_85@rospotrebnadzor.ru',
        email2: 'tkm_fbuz85@cgezo.ru',
      },
    ],
    filialLinks: [
      {
        id: 1,
        title: 'Пологовский филиал ФБУЗ «ЦГиЭ в ЗО»',
        link: '/filials/tokmak',
      },
      {
        id: 2,
        title: 'Бердянский филиал ФБУЗ «ЦГиЭ в ЗО»',
        link: '/filials/berdyansk',
      },
    ],
    tokmak: [
      {
        id: 1,
        title: 'Филиал ФБУЗ «ЦГиЭ в ЗО» в Пологовском, Ореховском, Гуляйпольском и Токмакском районах',
        address: 'Запорожская область, г. Токмак, ул. Гоголя, д. 44',
        image: 'http://via.placeholder.com/350x150',
        coordinates: [47.255104, 35.709187],
        tel: '+7-990-290-36-41',
        // email: 'fbuz_85@rospotrebnadzor.ru',
        email2: 'tkm_fbuz85@cgezo.ru',
      },
    ],
    berdyansk: [
      {
        id: 1,
        title: 'Филиал ФБУЗ «ЦГиЭ» в г.Бердянск, Бердянском, Черниговском и Приморском районах',
        address: '271100, Запорожская область, г. Бердянск, ул. Свободы, д. 115',
        image: 'http://via.placeholder.com/350x150',
        coordinates: [46.758643, 36.798345],
        tel: '+7-990-290-36-42',
        // email: 'fbuz_85@rospotrebnadzor.ru',
        email2: 'brd_fbuz85@cgezo.ru',
      },
    ],
  }),
  getters: {},
  mutations: {},
  actions: {},
};

export default FilialsStore;
