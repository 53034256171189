<template>
  <div
    class="home-navigation__center-contactLink home-navigation__center-contactLink--flex"
    v-for="item in news"
    :key="item.id"
  >
    <a :href="'/news#' + item.alias" style="color: black">
      <img
        v-if="item.images && item.images.length > 0"
        :src="item.images[0]"
        alt="картинка"
        class="news__previewImg"
      />
    </a>
    <div>
      <a :href="'/news#' + item.alias">
        <h4 class="news__previewTitle" style="color: black">
          {{ item.title }}
        </h4>
      </a>
      <a :href="'/news#' + item.alias"
        ><p v-html="truncateText(item.content, 500)" style="color: black"></p
      ></a>
      <a :href="'/news#' + item.alias">Подробнее</a>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Новость",

  computed: {
    ...mapState({
      news: (state) => state.NewsStore.news,
    }),
  },
  methods: {
    truncateText(text, length) {
      if (text.length > length) {
        return text.substring(0, length) + "..."; // Обрезаем текст до указанной длины и добавляем многоточие
      } else {
        return text;
      }
    },
  },
};
</script>

<style scoped></style>
