const RequisitesStore = {
	state: {
		mainOrganization:{
			fullName: 'Федеральное бюджетное учреждение здравоохранения «Центр гигиены и эпидемиологии в Запорожской области»',
			abbreviatedName: 'ФБУЗ «Центр гигиены и эпидемиологи в Запорожской области»',
			legalAddress: '272309, Запорожская обл. г.о. Мелитополь, Мелитополь, ул. Сухова, 6',
			locationAddress: '272309, Запорожская обл. г.о. Мелитополь, Мелитополь, ул. Сухова, 6',
			inn: '9001018744',
			kpp: '900101001',
			okpo: '48943691',
			oktmo:'23701000001',
			okved: '86.90.1',
			okfs: '12',
			okopf: '75103',
			ogrn: '1239000006574',
			telephonNumber: '+79900568597',
			bank: 'Отделение Запорожье банка России //УФК по Запорожской области, г. Мелитополь',
			checkingAccount: '03214643000000017000',
			bik: '042368903',
			correspondentAccount: '40102810545370000097',
			personalAccount: '20706НЛ7430 в УФК по Запорожской области'
		}
	},
	getters: {},
	mutations: {},
	actions: {},
};

export default RequisitesStore;
