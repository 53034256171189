<template>
  <details class="price__details" v-for="item in price" :key="item.id">
    <summary>{{ item.summary }}</summary>
    <table
      class="price__details-table"
      v-for="(priceData, index) in item.data"
      :key="index"
    >
      <tbody>
        <tr>
          <td style="width: 5%" :id="priceData.searchId">
            {{ priceData.number }}
          </td>
          <td>
            {{ priceData.title }}
          </td>
          <td style="width: 20%">{{ priceData.type }}</td>
          <td style="width: 15%">{{ priceData.rawPrice }}</td>
          <td style="width: 15%">{{ priceData.ndsPrice }}</td>
        </tr>
      </tbody>
    </table>
  </details>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Раздел прайс-листа",

  computed: {
    ...mapState({
      price: (state) => state.PriceTable.price,
    }),
  },
};
</script>

<style lang="scss" scoped></style>
