<template>
  <NavigationComp :isDisable="true" />
  <nav class="home-navigation">
    <div class="home-navigation__wrapper">
      <LeftSideHomeMenuComp />
      <div class="home-navigation__center">
        <p class="home-navigation__center-contactLink">
          Обращения принимаются по телефону
          <a href="tel:+7-990-228-53-70">+7-990-234-50-40</a>
          , E-mail
          <a href="mailto:fbuz85@cgezo.ru">fbuz85@cgezo.ru</a>
        </p>
        <p class="home-navigation__center-contactLink">
          <router-link to="/jobs">РАБОТА В ФБУЗ «Центр гигиены и эпидемиологии в Запорожской области»</router-link>
        </p>

        <div class="home-navigation__center-newsList">
          <h2 class="home-navigation__center-newsTitle">Новости</h2>
          <NewsComp />
        </div>
      </div>
      <RightSideHomeMenuComp />
    </div>
  </nav>
</template>

<script setup lang="ts">
import NavigationComp from '@/components/UI/Navigation/NavigationComp.vue';
import LeftSideHomeMenuComp from '@/components/UI/HomeMenu/LeftSideHomeMenuComp.vue';
import NewsComp from '@/components/UI/NewsComp.vue';
import RightSideHomeMenuComp from '@/components/UI/HomeMenu/RightSideHomeMenuComp.vue';
import { useHead } from '@vueuse/head';

useHead({
  title:
    'Главная | Федеральное бюджетное учреждение здравоохранения «Центр гигиены и эпидемиологии в Запорожской области»',
  meta: [
    {
      name: 'description',
      content: 'Федеральное бюджетное учреждение здравоохранения «Центр гигиены и эпидемиологии в Запорожской области»',
    },
  ],
});
</script>
