<template>
	<article class="contactCard">
		<h2 class="contactCard__title">{{ contact.title }}</h2>
		<p class="contactCard__tel">{{ contact.tel }}</p>
		<p v-if="contact.tel2" class="contactCard__tel">{{ contact.tel2 }}</p>
		<p v-if="contact.tel3" class="contactCard__tel">{{ contact.tel3 }}</p>
		<p v-if="contact.tel4" class="contactCard__tel">{{ contact.tel4 }}</p>
		<p v-if="contact.email" class="contactCard__email">{{ contact.email }}</p>
		<p v-if="contact.email2" class="contactCard__email">{{ contact.email2 }}</p>
	</article>
</template>

<script>
export default {
	name: "Карточка контакта",
	props: {
		contact: Object,
	},
};
</script>

<style lang="scss" scoped></style>
