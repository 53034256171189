<template>
  <nav class="navigation" v-if="isDisable">
    <div class="navigation__wrapper container">
      <router-link
        class="navigation__menu-list__main-link clickable"
        v-for="filialLink in filialLinks"
        :key="filialLink.id"
        :to="filialLink.link"
      >
        {{ filialLink.title }}
      </router-link>
    </div>
  </nav>
  <nav class="navigation" v-if="!isDisable">
    <div class="navigation__wrapper navigation__wrapper_baseline">
      <div class="navigation__menu-list">
        <router-link
          class="navigation__menu-list__main-link"
          v-for="item in mainMenu"
          :key="item.id"
          :to="item.url"
          :class="{ 'has-submenu': item.hasSubMenu }"
        >
          {{ item.title }}
          <div class="navigation__hover-menu" v-if="item.subMenu">
            <router-link
              class="navigation__menu-list__main-link sub-link"
              v-for="subItem in item.subMenu"
              :key="subItem.id"
              :to="subItem.url"
              :class="{ 'has-submenu': subItem.hasSubMenu }"
            >
              {{ subItem.title }}
              <div
                class="navigation__hover-submenu"
                v-if="subItem.subSubMenu && subItem.subSubMenu.length > 0"
              >
                <router-link
                  class="navigation__hover-submenu-item sub-link"
                  v-for="subSubItem in subItem.subSubMenu"
                  :key="subSubItem.id"
                  :to="subSubItem.url"
                >
                  {{ subSubItem.title }}
                </router-link>
              </div>
            </router-link>
          </div>
        </router-link>
      </div>
    </div>

    <button
      class="navigation__menu-btn navigation__menu-btn_hidden"
      @click="enableMobileMenu = !enableMobileMenu"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
        <path
          d="M0 96c0-17.7 14.3-32 32-32h384c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32h384c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zm448 160c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32h384c17.7 0 32 14.3 32 32z"
        />
      </svg>
    </button>
    <div class="navigation__mobile-menu-list" v-show="enableMobileMenu">
      <router-link
        class="navigation__mobile-menu-list__main-link"
        :to="link.url"
        v-for="link in mainMenu"
        :key="link.alias"
        >{{ link.title }}</router-link
      >
    </div>
  </nav>
</template>

<script>
import { mapState } from "vuex";
//import NavigationSubmenuComp from './NavigationSubmenuComp.vue';

export default {
  name: "Навигация",

  //components: { NavigationSubmenuComp },
  data() {
    return {
      enableMobileMenu: false,
      isActive: false,
      visibleSubMenu: "",
    };
  },
  props: {
    isDisable: Boolean,
  },
  computed: {
    ...mapState({
      mainMenu: (state) => state.NavigationStore.menuItems,
      filialLinks: (state) => state.FilialsStore.filialLinks,
    }),
  },
};
</script>

<style lang="scss" scoped></style>
