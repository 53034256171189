<template>
  <NavigationComp :isDisable="false" />
  <main class="main">
    <div class="main__wrapper container">
      <h2 class="main__wrapper-title">Услуги для населения</h2>
      <div class="main__servicesForPeopleList">
        <ServicesForPeopleCardComp
          v-for="service in servicesList"
          :key="service.id"
          :serviceCard="service"
        />
      </div>
    </div>
  </main>
</template>

<script>
import NavigationComp from "@/components/UI/Navigation/NavigationComp.vue";
import ServicesForPeopleCardComp from "@/components/UI/ServicesForPeopleCardComp.vue";
import { mapState } from "vuex";
import { useHead } from "@vueuse/head";

export default {
  components: { NavigationComp, ServicesForPeopleCardComp },
  name: "Услуги для населения",
  computed: {
    ...mapState({
      servicesList: (state) => state.ServicesStore.servicesForPeople,
    }),
  },

  setup() {
    useHead({
      title:
        "Услуги для населения | Федеральное бюджетное учреждение здравоохранения «Центр гигиены и эпидемиологии в Запорожской области»",
      meta: [
        {
          name: "documents",
          content:
            "Федеральное бюджетное учреждение здравоохранения «Центр гигиены и эпидемиологии в Запорожской области»",
        },
      ],
    });
  },
};
</script>
