import { v4 as uuidv4 } from 'uuid';

const Vacancy = {
	state: () => ({
		vacancies: [
			{
				id: uuidv4(),
				department: 'Отдел юридического сопровождения и закупок',
				vacancy: [
					{
						id: uuidv4(),
						title: 'Юрисконсульт /Отдел юридического сопровождения и закупок/',
						salary: 'от 40 000 руб.',
					},
					{
						id: uuidv4(),
						title: 'Начальник отдела /Отдел юридического сопровождения и закупок/',
						salary: 'от 55 000 руб.',
					},
				],
			},
			{
				id: uuidv4(),
				department: 'Отдел материально-технического обеспечения',
				vacancy: [
					{
						id: uuidv4(),
						title: 'Инженер /Отдел материально-технического обеспечения/',
						salary: 'от 40 000 руб.',
					},
				],
			},
			{
				id: uuidv4(),
				department: 'Отдел компьютерных технологий и программного обеспечения',
				vacancy: [
					{
						id: uuidv4(),
						title: 'Инженер /Отдел компьютерных технологий и программного обеспечения/',
						salary: 'от 40 000 руб.',
					},
					{
						id: uuidv4(),
						title: 'Программист /Отдел компьютерных технологий и программного обеспечения/',
						salary: 'от 35 000 руб.',
					},
				],
			},
			{
				id: uuidv4(),
				department: 'Отдел обеспечения безопасности, ЧС и мобилизационной подготовки',
				vacancy: [
					{
						id: uuidv4(),
						title: 'Заведующий отделом /Отдел обеспечения безопасности, ЧС и мобилизационной подготовки/',
						salary: 'от 45 000 руб.',
					},
				],
			},
			{
				id: uuidv4(),
				department: 'Отдел организации и методического обеспечения деятельности',
				vacancy: [
					{
						id: uuidv4(),
						title: 'Инженер /Отдел организации и методического обеспечения деятельности/',
						salary: 'от 40 000 руб.',
					},
					{
						id: uuidv4(),
						title: 'Заведующий отделом / Отдел организации и методического обеспечения деятельности/',
						salary: 'от 60 000 руб.',
					},
					{
						id: uuidv4(),
						title: 'Врач по общей гигиене /Отдел организации и методического обеспечения деятельности/',
						salary: 'от 55 000 руб.',
					},
					{
						id: uuidv4(),
						title: 'Помощник врача по общей гигиене /Отдел организации и методического обеспечения деятельности/',
						salary: 'от 45 000 руб.',
					},
				],
			},
			{
				id: uuidv4(),
				department: 'Отдел социально-гигиенического мониторинга',
				vacancy: [
					{
						id: uuidv4(),
						title: 'Заведующий отделом - врач-специалист /Отдел социально-гигиенического мониторинга/',
						salary: 'от 60 000 руб.',
					},
					{
						id: uuidv4(),
						title: 'Врач по общей гигиене /Отдел социально-гигиенического мониторинга/',
						salary: 'от 55 000 руб.',
					},
				],
			},
			{
				id: uuidv4(),
				department: 'Отдел гигиены и санэкспертиз	',
				vacancy: [
					{
						id: uuidv4(),
						title: 'Помощник врача-эпидемиолога /Отдел гигиены и санэкспертиз/',
						salary: 'от 45 000 руб.',
					},
					{
						id: uuidv4(),
						title: 'Врач по общей гигиене /Отдел гигиены и санэкспертизы/',
						salary: 'от 55 000 руб.',
					},
					{
						id: uuidv4(),
						title: 'Врач по коммунальной гигиене /Отдел гигиены и санэкспертизы/',
						salary: 'от 55 000 руб.',
					},
				],
			},
			{
				id: uuidv4(),
				department: 'Отдел эпидемиологии',
				vacancy: [
					{
						id: uuidv4(),
						title: 'Врач - эпидемиолог /отдел эпидемиологии/',
						salary: 'от 55 000 руб.',
					},
					{
						id: uuidv4(),
						title: 'Врач - дезинфектолог /Отдел эпидемиологии/',
						salary: 'от 55 000 руб.',
					},
					{
						id: uuidv4(),
						title: 'Помощник врача-эпидемиолога /Отдел эпидемиологии/',
						salary: 'от 45 000 руб.',
					},
					{
						id: uuidv4(),
						title: 'Медицинский дезинфектор /Отдел эпидемиологии/',
						salary: 'от 40 000 руб.',
					},
					{
						id: uuidv4(),
						title: 'Медицинский статистик /Отдел эпидемиологии/',
						salary: 'от 45 000 руб.',
					},
					{
						id: uuidv4(),
						title: 'Энтомолог /Отдел эпидемиологии/',
						salary: 'от 55 000 руб.',
					},
					{
						id: uuidv4(),
						title: 'Зоолог / Отдел эпидемиологии/',
						salary: 'от 55 000 руб.',
					},
				],
			},
			{
				id: uuidv4(),
				department: 'Отдел аккредитации, метрологии и стандартизации	',
				vacancy: [
					{
						id: uuidv4(),
						title: 'Начальник отдела /Отдел аккредитации, метрологии и стандартизации/',
						salary: 'от 55 000 руб.',
					},
					{
						id: uuidv4(),
						title: 'Инженер по метрологии /Отдел аккредитации, метрологии и стандартизации/',
						salary: 'от 40 000 руб.',
					},
				],
			},
			{
				id: uuidv4(),
				department:
					'Лаборатория санитарно-гигиенических исследований и контроля за ионизирующими и неионизирующими источниками излучения',
				vacancy: [
					{
						id: uuidv4(),
						title: 'Биолог /Отделение санитарно-гигиенических исследований/',
						salary: 'от 55 000 руб.',
					},
					{
						id: uuidv4(),
						title: 'Фельдшер - лаборант /Отделение санитарно-гигиенических исследований/',
						salary: '',
					},
					{
						id: uuidv4(),
						title: 'Лаборант /Отделение санитарно-гигиенических исследований/',
						salary: '',
					},
					{
						id: uuidv4(),
						title:
							'Врач по санитарно-гигиеническим лабораторным исследованиям /Отделение санитарно-гигиенических исследований/',
						salary: 'от 55 000 руб.',
					},
					{
						id: uuidv4(),
						title:
							'Химик-эксперт учреждения здравоохранения /Лаборатория санитарно-гигиенических исследований и контроля за ионизирующими и неионизирующими источниками излучений',
						salary: 'от 55 000 руб.',
					},
					{
						id: uuidv4(),
						title:
							'Фельдшер - лаборант /Отделение по контролю за ионизирующими и не ионизирующими источниками излучений/',
						salary: 'от 45 000 руб.',
					},
					{
						id: uuidv4(),
						title: 'Лаборант /Отделение по контролю за ионизирующими и не ионизирующими источниками излучений/',
						salary: 'от 40 000 руб.',
					},
					{
						id: uuidv4(),
						title:
							'Врач по общей гигиене /Отделение по контролю за ионизирующими и не ионизирующими источниками излучений/',
						salary: 'от 55 000 руб.',
					},
					{
						id: uuidv4(),
						title:
							'Врач по радиационной гигиене /Отделение по контролю за ионизирующими и не ионизирующими источниками излучений/',
						salary: 'от 55 000 руб.',
					},
					{
						id: uuidv4(),
						title:
							'Эксперт-физик по контролю за источниками ионизирующих и неионизирующих излучений /Отделение по контролю за ионизирующими и не ионизирующими источникам/',
						salary: 'от 55 000 руб.',
					},
				],
			},
			{
				id: uuidv4(),
				department: 'Микробиологическая лаборатория	',
				vacancy: [
					{
						id: uuidv4(),
						title: 'Биолог /Отделение бактериологических исследований и особо опасных инфекций/',
						salary: 'от 55 000 руб.',
					},
					{
						id: uuidv4(),
						title: 'Лаборант /Отделение бактериологических исследований и особо опасных инфекций/',
						salary: 'от 40 000 руб.',
					},
					{
						id: uuidv4(),
						title: 'Врач-бактериолог /Отделение бактериологических исследований и особо опасных инфекций/',
						salary: 'от 55 000 руб.',
					},
					{
						id: uuidv4(),
						title:
							'Фельдшер - лаборант (по бактериологии) /Отделение бактериологических исследований и особо опасных инфекций/',
						salary: 'от 45 000 руб.',
					},
					{
						id: uuidv4(),
						title: 'Биолог /Отделение молекулярно-генетических исследований/',
						salary: 'от 55 000 руб.',
					},
					{
						id: uuidv4(),
						title: 'Фельдшер - лаборант /Отделение молекулярно-генетических исследований/',
						salary: 'от 45 000 руб.',
					},
					{
						id: uuidv4(),
						title: 'Врач клинической лабораторной диагностики /Отделение молекулярно-генетических исследований/',
						salary: 'от 55 000 руб.',
					},
					{
						id: uuidv4(),
						title:
							'Врач клинической лабораторной диагностики /Отделение серологических исследований и классической вирусологии/',
						salary: 'от 55 000 руб.',
					},
					{
						id: uuidv4(),
						title: 'Врач-вирусолог /Отделение серологических исследований и классической вирусологии/',
						salary: 'от 55 000 руб.',
					},
					{
						id: uuidv4(),
						title: 'Фельдшер-лаборант /Отделение серологических исследований и классической вирусологии/',
						salary: 'от 45 000 руб.',
					},
					{
						id: uuidv4(),
						title: 'Биолог /Отделение паразитологических исследований/',
						salary: 'от 55 000 руб.',
					},
					{
						id: uuidv4(),
						title: 'Фельдшер - лаборант /Отделение паразитологических исследований/',
						salary: 'от 45 000 руб.',
					},
					{
						id: uuidv4(),
						title: 'Врач - паразитолог /Отделение паразитологических исследований/',
						salary: 'от 55 000 руб.',
					},
				],
			},
			{
				id: uuidv4(),
				department: 'Отделение приема, отбора, кодирования, регистрации проб и выдачи результатов	',
				vacancy: [
					{
						id: uuidv4(),
						title: 'Юрисконсульт /Отделение приема, отбора, кодирования, регистрации проб и выдачи результатов/',
						salary: 'от 40 000 руб.',
					},
					{
						id: uuidv4(),
						title: 'Инженер /Отделение приема, отбора, кодирования, регистрации проб и выдачи результатов/',
						salary: 'от 40 000 руб.',
					},
					{
						id: uuidv4(),
						title:
							'Врач по общей гигиене /Отделение приема, отбора, кодирования, регистрации проб и выдачи результатов/',
						salary: 'от 55 000 руб.',
					},
					{
						id: uuidv4(),
						title: 'Менеджер /Отделение приема, отбора, кодирования, регистрации проб и выдачи результатов/',
						salary: 'от 40 000 руб.',
					},
					{
						id: uuidv4(),
						title: 'Оператор ЭВМ /Отделение приема, отбора, кодирования, регистрации проб и выдачи результатов/',
						salary: 'от 40 000 руб.',
					},
				],
			},
		],
	}),
	rootGetters: {},
	mutations: {},
	actions: {},
};

export default Vacancy;
