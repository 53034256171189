<template>
  <NavigationComp :isDisable="false" />
  <main class="main">
    <div class="main__wrapper container">
      <h2 class="main__wrapper-title">Противодействие коррупции</h2>
      <DocumentListComp :documentsListName="'antiCorruptionDocuments'" />
    </div>
  </main>
</template>

<script>
import NavigationComp from "@/components/UI/Navigation/NavigationComp.vue";
import DocumentListComp from "@/components/UI/Documents/DocumentListComp.vue";

import { useHead } from "@vueuse/head";

export default {
  components: { NavigationComp, DocumentListComp },
  name: 'Противодействие коррупции',

  setup() {
    useHead({
      title:
        "Противодействие коррупции | Федеральное бюджетное учреждение здравоохранения «Центр гигиены и эпидемиологии в Запорожской области»",
      meta: [
        {
          name: "documents",
          content:
            "Федеральное бюджетное учреждение здравоохранения «Центр гигиены и эпидемиологии в Запорожской области»",
        },
      ],
    });
  },
};
</script>
