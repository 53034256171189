<template>
	<NavigationComp :isDisable="false" />
	<main class="news container">
		<article class="news__item" v-for="item in news" :key="item.id" :id="item.alias">
			<h2 class="news__title">{{ item.title }}</h2>
			<div class="news__img-list">
				<img v-for="(img, index) in item.images" :key="index" :src="img" :alt="item.title" />
			</div>
			<div class="news__video-list">
				<video v-for="(video, index) in item.videos" :key="index" :src="video" controls></video>
			</div>
			<div class="news__textContent" v-html="item.content"></div>
		</article>
	</main>
</template>

<script>
import NavigationComp from '@/components/UI/Navigation/NavigationComp.vue';
import { mapState } from 'vuex';
import { useHead } from '@vueuse/head';

export default {
	components: { NavigationComp },
	name: "Новости",
	computed: {
		...mapState({
			news: (state) => state.NewsStore.news,
		}),
	},

	setup() {
		useHead({
			title:
				'Новости | Федеральное бюджетное учреждение здравоохранения «Центр гигиены и эпидемиологии в Запорожской области»',
			meta: [
				{
					name: 'description',
					content: 'Новости Центра гигиены и эпидемиологии в Запорожской области.',
				},
			],
		});
	},
};
</script>

<style lang="scss" scoped></style>
