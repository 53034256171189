<template>
  <div class="servicesForPeople__card">
    <img class="servicesForPeople__card-img" :src="serviceCard.img" alt="" />
    <div class="servicesForPeople__card-description">
      <h2>
        <router-link :to="serviceCard.route">
          {{ serviceCard.title }}
        </router-link>
      </h2>
      <div v-html="serviceCard.content"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Карточка услуги для населения",

  props: {
    serviceCard: Object,
  },
};
</script>

<style scoped></style>
