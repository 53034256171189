<template>
  <NavigationComp :isDisable="false" />
  <main>
    <section class="education">
      <div class="education__wrapper container">
        <h2 class="education__title">Дистанционное гигиеническое обучение</h2>
        <p class="education__text">
          <span class="education__text_weight"
            >ФБУЗ «Центр гигиены и эпидемиологии в Запорожской области»</span
          >
          предлагает пройти профессиональную гигиеническую подготовку
          должностным лицам и работникам организаций, деятельность которых
          связана с производством, хранением, транспортировкой и реализацией
          пищевых продуктов и питьевой воды, воспитанием и обучением детей,
          коммунальным и бытовым обслуживанием населения в том числе
          <span class="education__text_red"
            >по дистанционной форме обучения</span
          >.
        </p>
        <p class="education__text">
          <span class="education__text_weight"
            >Дистанционная система обучения</span
          >
          - это инновационный подход, представляющий собой эффективную
          альтернативу очному обучению и позволяющий получить знания,
          практически не меняя привычный ритм личной и деловой жизни.
        </p>
        <p class="education__text">
          <span class="education__text_weight"
            >Данная услуга осуществляется только для лиц, которые собираются
            пройти гигиеническое обучение</span
          >
          <span class="education__text_red"> повторно</span>!
        </p>

        <p class="education__text">
          <span class="education__text_weight"
            >Преимущества дистанционной формы обучения</span
          >:
        </p>
        <ul class="education__text">
          <li>
            Вы можете сами выбрать себе время и место прохождения
            профессиональной гигиенической подготовки.
          </li>
          <li>
            Дистанционное обучение происходит в максимально комфортной и
            привычной для вас обстановке.
          </li>
        </ul>

        <p class="education__text">
          <span class="education__text_weight">Что для этого необходимо?</span>
        </p>
        <ul class="education__text">
          <li>
            Наличие компьютера, планшета или мобильного телефона, имеющего
            доступ в Интернет.
          </li>
          <li>Желание получить необходимые знания.</li>
          <li>Пройти гигиеническое обучение, соблюдая пошаговую инструкцию.</li>
        </ul>
        <details class="education__toggler">
          <summary class="education__toggler__title">
            Пошаговая инструкция
          </summary>
          <ol class="education__text education__toggler__list">
            <li class="counter-li">
              <span class="education__text_weight">Зайти на сайт ФБУЗ</span>
              «Центр гигиены и эпидемиологии в Запорожской области» с любого
              устройства, имеющего доступ в Интернет (компьютер, планшет,
              мобильный телефон)
            </li>
            <!-- <li class="counter-li">
								<span class="education__text_weight">Выбрать раздел «Услуги»</span> -> «Личные медицинские книжки.
								Профессиональная гигиеническая подготовка» -> «Дистанционное гигиеническое обучение»
							</li> -->
            <li class="counter-li">
              <span class="education__text_weight"
                >Пройти гигиеническое обучение и аттестацию</span
              >, следуя инструкции по изучению лекционного материала и
              прохождению тестирования
            </li>
            <li class="counter-li">
              После успешного прохождения тестирования
              <span class="education__text_weight"
                >запомнить номер протокола или распечатать его (по
                возможности)</span
              >
            </li>
            <li class="counter-li">
              При положительном результате тестирования по профессиональной
              гигиенической подготовке, необходимо
              <span class="education__text_weight"
                >обратиться в ФБУЗ «Центр гигиены и эпидемиологии в Запорожской
                области»</span
              >
              или его <a href="#">филиал</a> для
              <span class="education__text_weight">заключения договора</span> на
              прохождение дистанционного гигиенического обучения (при себе
              наличие паспорта обязательно); получения квитанции на оплату
              (<span class="education__text_weight">оплата </span>
              <span class="education__text_red">348 рублей</span>); и внесения
              отметки о прохождении профессиональной гигиенической подготовки в
              личную медицинскую книжку и защите ее голографическим знаком
            </li>
          </ol>
          <p
            class="education__text education__text_weight education__text_italic"
          >
            * при себе иметь личную медицинскую книжку с пройденным медицинским
            осмотром.
          </p>
        </details>
        <p class="education__text">
          При
          <span class="education__text_weight"
            >неудовлетворительном результате</span
          >
          тестирования отметка в личную медицинскую книжку
          <span class="education__text_weight">не вносится</span>.
          Неаттестованные должностные лица и работники организаций, деятельность
          которых связана с производством, хранением, транспортировкой и
          реализацией пищевых продуктов и питьевой воды, воспитанием и обучением
          детей, коммунальным и бытовым обслуживанием населения,
          <span class="education__text_weight"
            >не допускаются к работе и направляются на повторную
            профессиональную гигиеническую подготовку</span
          >.
        </p>
        <p class="education__text">
          Для того, чтобы начать обучение, пройдите по ссылке ниже, выберите Ваш
          район Запорожской области, группу, к которой Вы относитесь и откройте
          материал по выбранной форме обучения. После изучения материала, Вы
          можете пройти тестирование, на основании результатов которого,
          осуществляется оформление личных медицинских книжек.
        </p>
        <details class="education__toggler">
          <summary class="education__toggler__title">
            Инструкция по изучению лекционного материала и прохождению
            тестирования
          </summary>
          <ol class="education__text education__toggler__list">
            <li class="counter-li">Выбрать: «Пройти обучение»</li>
            <li class="counter-li">
              Заполнить поля:
              <ul>
                <li class="without-counter-li">
                  Фамилия Имя Отчество (полностью, как в паспорте)
                </li>
                <li class="without-counter-li">
                  номер мобильного телефона (для связи с Вами)
                </li>
                <li class="without-counter-li">
                  адрес электронной почты (данное поле можно не заполнять)
                </li>
                <li class="without-counter-li">
                  указать номер Вашей личной медицинской книжки
                </li>
              </ul>
            </li>
            <li class="counter-li">
              Поставить галочку в графе «Согласен на обработку моих персональных
              данных», после заполнения полей нажать «Зарегистрироваться»
            </li>
            <li class="counter-li">
              На следующей странице заполнить графы:
              <ul>
                <li class="without-counter-li">дата рождения</li>
                <li class="without-counter-li">домашний адрес (полностью)</li>
                <li class="without-counter-li">
                  должность (как в трудовом договоре)
                </li>
                <li class="without-counter-li">
                  место работы (точное наименование организации ввести
                  самостоятельно, или выбрать из списка) На этой же странице
                  будет указан пароль для повторно входа. Его можно запомнить
                  или изменить на другой более удобный Вам. После заполнения
                  полей нажать «Продолжить».
                </li>
              </ul>
            </li>
            <li class="counter-li">
              Выбрать раздел, по которому вы бы хотели пройти обучение и
              тестирование
            </li>
            <li class="counter-li">
              Поставить галочку в графе «Согласен с кодексом академической
              добросовестности»
            </li>
            <li class="counter-li">
              Выбрать способ оплаты (<span class="education__text_weight"
                >оплата
              </span>
              <span class="education__text_red">348 рублей</span>)
              <ul>
                <li class="without-counter-li">
                  Если Вы еще не приходили в Центр для заключения договора
                  необходимо выбрать «Банковский перевод» для оплаты
                  самостоятельно по предложенной квитанции», или «Оплата в
                  кассе»
                </li>
                <li class="without-counter-li">
                  Если Вы уже заключили договор, или проходите по договору
                  заключенному Вашей организацией (работодателем) с Центром,
                  необходимо выбрать «По договору», при этом нужно указать номер
                  договора.
                </li>
              </ul>
            </li>
            <li class="counter-li">
              Изучить лекционный материал. Обратите внимание,
              <span class="education__text_weight"
                >Вы не будете допущены к сдаче зачета, пока не просмотрите весь
                представленный материал</span
              >.
            </li>
            <li class="counter-li">
              После изучения материала нажать «Начать тест» (внизу страницы).
              Ответить на вопросы, представленные на странице.
            </li>
            <li class="counter-li">
              После прохождения обучения (в случае успешной сдачи теста)
              распечатать/сохранить на электронном устройстве протокол
              аттестации или запомнить номер протокола. В случае, если зачет
              сдан не был, еще раз повторить учебный материал и попробовать
              второй раз (всего Вам предоставляется 3 попытки для сдачи теста).
            </li>
          </ol>
        </details>
        <p class="education__text">
          <span class="education__text_weight"
            >Чтобы пройти обучение и сдать тестовый контроль необходимо
            выполнить следующее:</span
          >
        </p>
        <p class="education__text">
          Лица, получившие положительные результаты тестирования, предоставляют
          их в ФБУЗ «Центр гигиены и эпидемиологии в Запорожской области» или
          его филиал для внесения отметки о прохождении профессиональной
          гигиенической подготовки в личную медицинскую книжку и защите ее
          голографическим знаком. * При себе иметь личную медицинскую книжку с
          пройденным медицинским осмотром.
        </p>
        <p class="education__text">
          <span class="education__text_red"
            >Данная услуга осуществляется только для лиц, которые собираются
            пройти гигиеническое обучение повторно!</span
          >
        </p>

        <div class="education__link">
          <p class="education__text">Пройти обучение:</p>
          <a href="https://gigtest.ru/test/site/signup?department_id=993"
            ><img
              src="@/assets/img/content/education.png"
              alt="Пройти обучение"
          /></a>
          <a href="https://gigtest.ru/test/site/signup?department_id=993">
            ссылка</a
          >
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import NavigationComp from "@/components/UI/Navigation/NavigationComp.vue";
import { useHead } from "@vueuse/head";

export default {
  components: { NavigationComp },
  name: "Образование",
  setup() {
    useHead({
      title:
        "Образование | Федеральное бюджетное учреждение здравоохранения «Центр гигиены и эпидемиологии в Запорожской области»",
      meta: [
        {
          name: "description",
          content:
            "ФБУЗ «Центр гигиены и эпидемиологии в Запорожской области» предлагает пройти профессиональную гигиеническую подготовку должностным лицам и работникам организаций, деятельность которых связана с производством, хранением, транспортировкой и реализацией пищевых продуктов и питьевой воды, воспитанием и обучением детей, коммунальным и бытовым обслуживанием населения в том числе по дистанционной форме обучения.",
        },
      ],
    });
  },
};
</script>
