const ServicesStore = {
  state: {
    servicesForPeople: [
      {
        id: 1,
        img: require("@/assets/img/icons/servicesForPeople/acarus.webp"),
        route: "/tick-research",
        title: "Исследование клещей",
        content: `<p>На 4 инфекции методом ПЦР: клещевой энцефалит, клещевой бореллиоз,
        анаплазмоз, эрлихиоз</p>`,
      },
      {
        id: 2,
        img: require("@/assets/img/icons/servicesForPeople/labCenter.webp"),
        route: "/services-for-people",
        title: "Лабораторные центр (анализы)",
        content: `<ul class="servicesForPeople__card-parentList">
              <li>
                Объектов окружающей среды:
                <ul class="servicesForPeople__card-childrenList">
                  <li>вода колодцев и скважин, открытых водоемов.</li>
                  <li>
                    почва на тяжелые металлы, бензапирен и другие загрязнители,
                    почвы на наличие гельминтов, яиц и личинок гельминтов и
                    насекомых
                  </li>
                  <li>и др.</li>
                </ul>
              </li>
              <li>
                Большой выбор микробиологических, паразитологических и
                вирусологических исследований, в т.ч. клинического материала.
              </li>
            </ul>`,
      },
      {
        id: 3,
        img: require("@/assets/img/icons/servicesForPeople/medBook.webp"),
        route: "/services-for-people",
        title: "Личные медицинские книжки и гигиеническое обучение",
        content: ``,
      },
      {
        id: 4,
        img: require("@/assets/img/icons/servicesForPeople/disinfection.webp"),
        route: "/services-for-people",
        title: "Дезинфекция, Дезинсекция, Дератизация",
        content: ``,
      },
    ],
  },
  getters: {},
  mutations: {},
  actions: {},
};

export default ServicesStore;
