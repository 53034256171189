<template>
	<NavigationComp :isDisable="false" />
	<main class="requisites container">
		<h1 class="requisites__title">Реквизиты</h1>
		<table class="requisites__table">
			<tbody>
				<tr>
					<td class="requisites__table-td requisites__table-td--weight">Полное наименование учреждения</td>
					<td class="requisites__table-td">
						{{requisites.fullName}}
					</td>
				</tr>
				<tr>
					<td class="requisites__table-td requisites__table-td--weight">Сокращенное наименование учреждение</td>
					<td class="requisites__table-td">{{requisites.abbreviatedName}}</td>
				</tr>
				<tr>
					<td class="requisites__table-td requisites__table-td--weight">Юридический адрес</td>
					<td class="requisites__table-td">{{requisites.legalAddress}}</td>
				</tr>
				<tr>
					<td class="requisites__table-td requisites__table-td--weight">Адрес местонахождения</td>
					<td class="requisites__table-td">{{requisites.locationAddress}}</td>
				</tr>
				<tr>
					<td class="requisites__table-td requisites__table-td--weight">ИНН/КПП</td>
					<td class="requisites__table-td">{{requisites.inn}} / {{requisites.kpp}}</td>
				</tr>
				<tr>
					<td class="requisites__table-td requisites__table-td--weight">ОКПО</td>
					<td class="requisites__table-td">{{requisites.okpo}}</td>
				</tr>
				<tr>
					<td class="requisites__table-td requisites__table-td--weight">ОКТМО</td>
					<td class="requisites__table-td">{{requisites.oktmo}}</td>
				</tr>
				<tr>
					<td class="requisites__table-td requisites__table-td--weight">ОКВЭД</td>
					<td class="requisites__table-td">{{requisites.okved}}</td>
				</tr>
				<tr>
					<td class="requisites__table-td requisites__table-td--weight">ОКФС\ОКОПФ</td>
					<td class="requisites__table-td">{{requisites.okfs}} /{{requisites.okopf}}</td>
				</tr>
				<tr>
					<td class="requisites__table-td requisites__table-td--weight">ОГРН</td>
					<td class="requisites__table-td">{{requisites.ogrn}}</td>
				</tr>
				<tr>
					<td class="requisites__table-td requisites__table-td--weight">Телефон</td>
					<td class="requisites__table-td">{{requisites.telephonNumber}}</td>
				</tr>
				<tr>
					<td class="requisites__table-td requisites__table-td--weight">Банк</td>
					<td class="requisites__table-td">{{requisites.bank}}</td>
				</tr>
				<tr>
					<td class="requisites__table-td requisites__table-td--weight">Расч\счет</td>
					<td class="requisites__table-td">{{requisites.checkingAccount}}</td>
				</tr>
				<tr>
					<td class="requisites__table-td requisites__table-td--weight">Бик</td>
					<td class="requisites__table-td">{{requisites.bik}}</td>
				</tr>
				<tr>
					<td class="requisites__table-td requisites__table-td--weight">Кор\счет</td>
					<td class="requisites__table-td">{{requisites.correspondentAccount}}</td>
				</tr>
				<tr>
					<td class="requisites__table-td requisites__table-td--weight">Лицевой счет</td>
					<td class="requisites__table-td">{{requisites.personalAccount}}</td>
				</tr>
			</tbody>
		</table>
	</main>
</template>

<script>
import NavigationComp from '@/components/UI/Navigation/NavigationComp.vue';
import { mapState } from 'vuex';
import { useHead } from '@vueuse/head';

export default {
	components: { NavigationComp },
	name: 'Реквизиты',

	computed: {
		...mapState({
			requisites: (state) => state.RequisitesStore.mainOrganization,

		}),
	},

	setup() {
		useHead({
			title:
				'Реквизиты | Федеральное бюджетное учреждение здравоохранения «Центр гигиены и эпидемиологии в Запорожской области»',
			meta: [
				{
					name: 'description',
					content: 'Реквизиты Центра гигиены и эпидемиологии в Запорожской области.',
				},
			],
		});
	},
};
</script>

<style lang="scss" scoped></style>
