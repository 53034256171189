<template>
  <NavigationComp :isDisable="false" />
  <main class="medBooks container">
    <h1 class="medBooks__title">
      Личные медицинские книжки. Профессиональная гигиеническая подготовка
    </h1>
    <p class="medBooks__text">
      Личная медицинская книжка утверждена приказом Федеральной службы по
      надзору в сфере защиты прав потребителей и благополучия человека № 402 от
      20.05.2005 г. Зарегистрированным Минюстом Российской Федерации 01.06.2005
      г. Регистрационный номер № 6674.
    </p>
    <p class="medBooks__text">
      Личная медицинская книжка приобретается в организациях, уполномоченных
      Роспотребнадзором.
    </p>
    <p class="medBooks__text">
      На территории Запорожской области такой организацией является «Центр
      гигиены и эпидемиологии».
    </p>
    <p class="medBooks__text">
      Личная медицинская книжка подлежит голографированию в организациях,
      уполномоченных Роспотребнадзором.
    </p>
    <p class="medBooks__text">
      Личная медицинская книжка должна иметь печать организации
      Роспотребнадзора, выдававшей медицинскую книжку, а также подпись владельца
      и хранится у администрации организации или индивидуального предпринимателя
      и может быть выдана работнику по его требованию.
    </p>
    <p class="medBooks__text">
      При увольнении и переходе на другое место работы личная медицинская книжка
      остаётся у владельца и предъявляется по месту новой работы.
    </p>
    <p class="medBooks__text">
      Результат аттестации по профессиональной гигиенической подготовке
      подтверждается штампом организации, уполномоченной Роспотребнадзором.
    </p>
    <p class="medBooks__text">
      ЛМК является документом строгой отчетности и подлежит регистрации в едином
      реестре Российской Федерацию
    </p>

    <ul class="medBooks__ulList">
      <h3>Критерии подлинности ЛМК:</h3>
      <li class="medBooks__ulList--marker">
        наличие водяных знаков на каждой странице;
      </li>
      <li class="medBooks__ulList--marker">
        номер ЛМК, нанесен типографским способом;
      </li>
      <li class="medBooks__ulList--marker">
        наличие круглой печати Центра гигиены и эпидемиологии или его филиала,
        выдавшего личную медицинскую книжку;
      </li>
      <li class="medBooks__ulList--marker">
        фотографию владельца заверяет круглая защитная голограмма;
      </li>
      <li class="medBooks__ulList--marker">
        страница с данными владельца ламинирована;
      </li>
      <li class="medBooks__ulList--marker">книжка прошита нитками.</li>
    </ul>

    <img src="@/assets/img/content/medBook.webp" alt="medBook" />
  </main>
</template>

<script>
import NavigationComp from "@/components/UI/Navigation/NavigationComp.vue";

import { useHead } from "@vueuse/head";

export default {
  components: { NavigationComp },
  name: "Медицинские книжки",
  setup() {
    useHead({
      title:
        "Медицинские книжки | Федеральное бюджетное учреждение здравоохранения «Центр гигиены и эпидемиологии в Запорожской области»",
      meta: [
        {
          name: "description",
          content:
            "Медицинские книжки Центра гигиены и эпидемиологии в Запорожской области.",
        },
      ],
    });
  },
};
</script>

<style lang="scss" scoped></style>
