import { createRouter, RouteRecordRaw, createWebHistory } from "vue-router";

import HomePage from "@/pages/HomePage.vue";
import EducationPage from "@/pages/EducationPage.vue";
import FilialsMapPage from "@/pages/Filials/FilialsMapPage.vue";
import FilialMapTokmakPage from "@/pages/Filials/FilialMapTokmakPage.vue";
import FilialMapBerdyanskPage from "@/pages/Filials/FilialMapBerdyanskPage.vue";
import DocumentsPage from "@/pages/AboutInfo/DocumentsPage.vue";
import AboutPage from "@/pages/AboutInfo/AboutPage.vue";
import ContactsPage from "@/pages/Contacts/ContactsPage.vue";
import RequisitesPage from "@/pages/Contacts/RequisitesPage.vue";
import ManagementPage from "@/pages/AboutInfo/ManagementPage.vue";
import FederalServiceDepartmentPage from "@/pages/FederalServiceDepartmentPage.vue";
import LabCenterPage from "@/pages/LabCenter/LabCenterPage.vue";
import StructureILCPage from "@/pages/LabCenter/StructureILCPage.vue";
import AntiCorruptionPage from "@/pages/AboutInfo/AntiCorruptionPage.vue";
import ServicesPage from "@/pages/Services/ServicesPage.vue";
import ServicesForPeoplePage from "@/pages/Services/ServicesForPeoplePage.vue";
import TickResearchPage from "@/pages/Services/TickResearch/TickResearchPage.vue";
import NewsPage from "@/pages/NewsPage.vue";
import PricePage from "@/pages/PricePage.vue";
import MedBooksPage from "@/pages/MedBooksPage.vue";
import VacancyPage from "@/pages/VacancyPage.vue";
import StructurePage from "@/pages/AboutInfo/StructurePage.vue";
import TelBookPage from "@/pages/Contacts/TelBookPage.vue";
import FormForTickResearchPage from "@/pages/Services/TickResearch/FormForTickResearchPage.vue";
import NotFoundPage from "@/pages/NotFoundPage.vue";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "Главная",
    component: HomePage,
  },
  {
    path: "/education",
    name: "Образование",
    component: EducationPage,
  },
  {
    path: "/filials",
    name: "Филиалы",
    component: FilialsMapPage,
  },
  {
    path: "/filials/tokmak",
    name: "Филиал Токмак",
    component: FilialMapTokmakPage,
  },
  {
    path: "/filials/berdyansk",
    name: "Филиал Бердянск",
    component: FilialMapBerdyanskPage,
  },
  {
    path: "/documents",
    name: "Документы",
    component: DocumentsPage,
  },
  {
    path: "/about",
    name: "Об учереждении",
    component: AboutPage,
  },
  {
    path: "/contacts",
    name: "Контакты",
    component: ContactsPage,
  },
  {
    path: "/requisites",
    name: "Реквизиты",
    component: RequisitesPage,
  },
  {
    path: "/management",
    name: "Руководство",
    component: ManagementPage,
  },
  {
    path: "/federal-service-department",
    name: "Департамент Федеральной Службы",
    component: FederalServiceDepartmentPage,
  },
  {
    path: "/lab",
    name: "Лабораторный центр",
    component: LabCenterPage,
  },
  {
    path: "/structure-ilc",
    name: "Структура ИЛЦ",
    component: StructureILCPage,
  },
  {
    path: "/anti-corruption",
    name: "Противодействие коррупции",
    component: AntiCorruptionPage,
  },
  {
    path: "/services",
    name: "Услуги",
    component: ServicesPage,
  },
  {
    path: "/services-for-people",
    name: "Услуги для населения",
    component: ServicesForPeoplePage,
  },
  {
    path: "/tick-research",
    name: "Исследование клещей",
    component: TickResearchPage,
  },
  {
    path: "/news",
    name: "Новости",
    component: NewsPage,
  },
  {
    path: "/price",
    name: "Прайс-лист",
    component: PricePage,
  },
  {
    path: "/medBooks",
    name: "Медицинские книжки",
    component: MedBooksPage,
  },
  {
    path: "/jobs",
    name: "Вакансии",
    component: VacancyPage,
  },
  {
    path: "/structure",
    name: "Структура",
    component: StructurePage,
  },
  {
    path: "/phonebook",
    name: "Телефонный справочник",
    component: TelBookPage,
  },
  {
    path: "/formForTickResearch",
    name: "Заявление на исследование клеща",
    component: FormForTickResearchPage,
  },
  {
    path: "/:CatchAll(.*)",
    name: "404",
    component: NotFoundPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
