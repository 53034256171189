<template>
  <section class="contactCardsList container">
    <ContactCardComp
      v-for="contact in contactsList"
      :key="contact.id"
      :contact="contact"
    />
  </section>
</template>

<script>
import { mapState } from "vuex";
import ContactCardComp from "./ContactCardComp.vue";
export default {
  name: "Список карточек контактов",
  components: {
    ContactCardComp,
  },
  computed: {
    ...mapState({
      contactsList: (state) => state.ContactsList.contactsList,
    }),
  },
};
</script>

<style lang="scss" scoped></style>
