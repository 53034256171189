<template>
	<HeaderComp />
	<router-view />
	<FooterComp />
</template>

<script>
import HeaderComp from '@/components/UI/HeaderComp.vue';
import FooterComp from '@/components/UI/FooterComp.vue';

export default {
	components: { HeaderComp, FooterComp},
};
</script>
