<template>
  <NavigationComp :isDisable="false" />
  <section class="map">
    <div class="map__wrapper">
      <FilialDescriptionComponent v-for="filial in filialList" :key="filial.id" :filial="filial" />
    </div>
    <yandex-map
      :settings="{
        location: {
          center: [36.542762, 47.060318],
          zoom: 9,
        },
      }"
    >
      <yandex-map-default-scheme-layer :settings="{ theme: 'light' }" />
      <yandex-map-controls :settings="{ position: 'right' }">
        <yandex-map-zoom-control />
      </yandex-map-controls>
      <yandex-map-default-features-layer />
      <yandex-map-marker
        v-for="(marker, index) in markers"
        :key="index"
        :settings="marker"
        @click="handleMarkerClick(index)"
      >
        <div class="marker" />
        <BalloonComponent v-if="activeMarker === index" :filial="filialList[index]" />
      </yandex-map-marker>
    </yandex-map>
  </section>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useHead } from '@vueuse/head';
import NavigationComp from '@/components/UI/Navigation/NavigationComp.vue';
import BalloonComponent from '@/components/YandexMap/BalloonComponent.vue';
import FilialDescriptionComponent from '@/components/YandexMap/FilialDescriptionComponent.vue';

import {
  YandexMap,
  YandexMapControls,
  YandexMapDefaultSchemeLayer,
  YandexMapZoomControl,
  YandexMapDefaultFeaturesLayer,
  YandexMapMarker,
} from 'vue-yandex-maps';

import { YMapMarkerProps } from '@yandex/ymaps3-types/imperative/YMapMarker';

useHead({
  title:
    'Филиалы | Федеральное бюджетное учреждение здравоохранения «Центр гигиены и эпидемиологии в Запорожской области»',
  meta: [
    {
      name: 'filials',
      content: 'Филиалы ФБУЗ «Центр гигиены и эпидемиологии в Запорожской области»',
    },
  ],
});

const store = useStore();
const filialList = computed(() => store.state.FilialsStore.filialListFullInfo);

const activeMarker = ref<number | null>(null);

const handleMarkerClick = (index: number) => {
  activeMarker.value = activeMarker.value === index ? null : index;
};

const markers: YMapMarkerProps[] = [
  {
    coordinates: [36.798345, 46.758643],
  },
  {
    coordinates: [35.707008, 47.260461],
  },
];
</script>

<style scoped>
/* Временный стиль маркера */

.marker {
  position: relative;
  width: 20px;
  height: 20px;
  background: #ff0000;
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  text-align: center;
  color: #fff;
  font-weight: bold;
  line-height: 20px;
}
</style>
