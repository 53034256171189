<template>
  <div class="main__wrapper-document">
          <img :src="icon.url" alt="document_icon">

          <div class="main__wrapper-document-description">
            <a :href="document.file"> 
             {{ document.title }}
            </a>
            <span>{{document.size}}</span>
          </div>
        </div>
</template>

<script>
    export default {
      name: "Документ",
      props:{
            document:Object,
            icon:Object
        }
    }
</script>

<style scoped>

</style>