import { v4 as uuidv4 } from 'uuid';


const NewsStore = {
  state: {
    news: [
      {
        id: uuidv4(),
        title: 'Экологический диктант',
        alias: 'ekodictant',
        content: `
				<p class="news__text--indent">
					Федеральная служба по надзору в сфере защиты прав потребителей и благополучия человека в соответствии с письмом Комитета Совета Федерации Федерального Собрания Российской Федерации по аграрно-продовольственной политике и природопользованию о Всероссийском экологическом диктанте (информация размещена на интернет-портале https://экодиктант.рус), запланированном в период с 25 ноября по 8 декабря 2024 года, предлагает оказать информационную поддержку в проведении Всероссийского экологического диктанта с размещением информации на официальных сайтах территориальных органов и подведомственных организаций Роспотребнадзора.
				</p>
				`,
        images: [],
      },
      {
        id: uuidv4(),
        title: 'Внимание, ГРИПП',
        alias: 'gripp',
        content: '',
        images: [require('@/assets/img/news/gripp.jpg')],
      },
      {
        id: uuidv4(),
        title:
          'Информация для выпускников общеобразовательных учреждений и желающих получить медицинское образование в ВУЗах Российской Федерации ',
        alias: 'target-education',
        content: `
						<p class="news__text--indent">
						Управление Федеральной службы по надзору в сфере защиты прав потребителей и благополучия человека по Запорожской области (далее- Управление Роспотребнадзора) информирует о том, что Управлению Роспотребнадзора и его подведомственному учреждению ФБУЗ "Центр гигиены и эпидемиологии в Запорожской области" выделяются целевые места для подготовки специалистов по направлению 32.05.01 «Медико-профилактическое дело» за счет бюджетных ассигнований федерального бюджета на места в пределах квоты на целевой прием в медицинские высшие учебные заведения Российской Федерации.
						</p>    
						<br /> 
						<p class="news__text--indent">
						В рамках целевого обучения выделены квоты в количестве 12 мест в следующие образовательные учреждения:
						</p>
						<br />
						<table class="news__table">
			<thead>
				<tr>
					<th class="news__table-th">Наименование территориального органа /подведомственного учреждения Роспотребнадзора</th>
					<th class="news__table-th">Распределение мест целевого обучения по образовательным организациям</th>
					<th class="news__table-th">Квота приема</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td class="news__table-td news__table-td--weight">Управление Роспотребнадзора по Запорожской области</td>
					<td class="news__table-td">Управление Роспотребнадзора по Запорожской области	ФГБОУ ВО "Волгоградский государственный медицинский университет" Минздрава России</td>
					<td class="news__table-td">1</td>
				</tr>
				<tr>
					<td class="news__table-td news__table-td--weight">Управление Роспотребнадзора по Запорожской области</td>
					<td class="news__table-td">ФГБОУ ВО "Ростовский государственный медицинский университет" Минздрава России</td>
					<td class="news__table-td">1</td>
				</tr>
				<tr>
					<td class="news__table-td news__table-td--weight">Управление Роспотребнадзора по Запорожской области</td>
					<td class="news__table-td">ФГБОУ ВО "Северо-Западный государственный медицинский университет им. И.И. Мечникова" Минздрава России</td>
					<td class="news__table-td">4</td>
				</tr>
				<tr>
					<td class="news__table-td news__table-td--weight">ФБУЗ "Центр гигиены и эпидемиологии в Запорожской области"</td>
					<td class="news__table-td">ФГБОУ ВО "Волгоградский государственный медицинский университет" Минздрава России</td>
					<td class="news__table-td">2</td>
				</tr>
				<tr>
					<td class="news__table-td news__table-td--weight">ФБУЗ "Центр гигиены и эпидемиологии в Запорожской области"</td>
					<td class="news__table-td">ФГБОУ ВО "Ростовский государственный медицинский университет" Минздрава России</td>
					<td class="news__table-td">1</td>
				</tr>
				<tr>
					<td class="news__table-td news__table-td--weight">ФБУЗ "Центр гигиены и эпидемиологии в Запорожской области"</td>
					<td class="news__table-td">ФГБОУ ВО "Северо-Западный государственный медицинский университет им. И.И. Мечникова" Минздрава России</td>
					<td class="news__table-td">3</td>
				</tr>
			</tbody>
		</table>
						<br />
						<p class="news__text--indent">
						С гражданином, изъявившем желание получить квоту, будет заключен договор о целевом обучении. После окончания ВУЗа - работа в органах Роспотребнадзора и подведомственных ему учреждениях на территории Запорожской области).  
						</p>
						<br />
						<p class="news__text--indent">
						Для получения подробной информации необходимо обращаться в Управление Роспотребнадзора по Запорожской области – г. Мелитополь, ул. Сухова, д. 6, 2 этаж, каб. 25 или по телефону +7 990-290-25-21.
						</p>
				`,
        images: [require('@/assets/img/news/7_target-education.webp')],
      },
      {
        id: uuidv4(),
        title: 'ОСТОРОЖНО КЛЕЩИ – ПЕРЕНОСЧИКИ ВОЗБУДИТЕЛЕЙ ОПАСНЫХ ИНФЕКЦИОННЫХ ЗАБОЛЕВАНИЙ!!!',
        alias: 'acarus',
        content: `
						<h4 class="news__title">Памятка для детей и взрослых</h4>
						<p class="news__text--indent">
						На территории Запорожской области проснулись клещи, которые могут быть переносчиками опасных инфекций, как например, клещевого вирусного энцефалита, иксодовых клещевых боррелиозов, моноцитарного эрлихиоза человека, гранулоцитарного анаплазмоза человека, ку-лихорадки, Конго-Крымской геморрагическая лихорадки, туляремии и др. Активными они становятся уже при температуре +5 градусов, а пик в нашем регионе приходится на май и сентябрь.
						</p>     
						<br />
						<h4 class="news__subtitle">УКУС КЛЕЩА</h4>
						<p class="news__text--indent">
						В лесу, лесопарковой зоне клещи концентрируются вдоль троп и, находясь на ветках или траве, при приближении животного или человека могут прицепиться. Присасывание членистоногих наблюдается, чаще всего в области шеи, волосистой части головы, спины, подмышечных впадин и паховой области и др. В слюне клеща находится обезболивающее вещество, поэтому человек или животное не чувствует укус. При его обнаружении важно как можно быстрее извлечь, так как чем дольше он находится на теле, тем выше вероятность передачи возбудителя инфекции. 
						</p>
						<p class="news__text--indent">
						Удалить паразита можно пинцетом или пальцами, обернув их чистой марлей, ухватив членистоногое как можно ближе к голове, приподнять, прокрутить несколько раз и потянуть вверх. Затем его помешают в емкость (пузырек, пробирка, маленькая банка, одноразовый маленький контейнер и т.д.) с влажной ватой, тканью или травинками для создания влажности, закрывают крышкой и отправляют в лабораторию, чтобы проверить, не был ли он переносчиком возбудителя инфекционного заболевания. Лабораторное исследование клещей методом ПЦР проводится во ФБУЗ «Центр гигиены и эпидемиологии в Запорожской области», г. Мелитополь, ул. Сухова 6. В случае отсутствия возможности доставки живого клеща в лабораторию в течение 3 суток– клеща нужно сжечь или залить кипятком. Место укуса и руки обязательно необходимо обработать аптечным раствором 70 % этилового спирта или 3 % перекиси водорода или 1 % хлоргексидина. Руки вымыть с мылом. 
						</p>
						<p class="news__text--indent news__text--red news__text--center">
						Насторожиться следует, если на месте укуса клеща возникло овальное или округлое пятно с постепенно проступающей каймой ярко-красного цвета и /или появились озноб, сонливость, упадок сил, светобоязнь, боль в суставах, температура, зуд, снижение артериального давления, учащенное сердцебиение, увеличение лимфатических узлов. В этом случае нужно обязательно показаться врачу. 
						</p>
						<br />
						<h4 class="news__subtitle">Профилактика</h4>
						<p class="news__text--indent">
						На природе лучше находиться в головном уборе и в закрытой одежде. Не ходить по высокой траве в открытой одежде. Также на одежду и кожу рекомендуется наносить репелленты против клещей. После прогулки необходимо внимательно осмотреть все тело и вещи перед тем, как зайти в помещение.
						</p>
						<br />
						<h4 class="news__subtitle">Порядок проведения лабораторных исследований клещей во ФБУЗ «Центр гигиены и эпидемиологии в Запорожской области»</h4>
						<p class="news__text--indent">
						Снятого клеща необходимо поместить в емкость (пузырек, пробирка, маленькая банка, одноразовый маленький контейнер и т.д.) с влажной ватой, тканью или травинками для создания влажности, закрывают крышкой и как можно скорее отправляют в лабораторию. К исследованиям принимаются клещи, доставленные в лабораторию в течение 3 дней от момента снятия. Срок исполнения исследований – от 2 до 4-х рабочих дней (не включая дату сдачи материала). Результат исследования (протокол) можно получить в электронном виде по электронной почте на личный e-mail заказчика или в бумажном виде на руки, обратившись в ФБУЗ Центр гигиены и эпидемиологии в Запорожской области, г. Мелитополь, ул. Сухова 6. О стоимости исследований можно ознакомиться на сайте в разделе Услуги.
						</p>
						<br />
						<h4 class="news__subtitle">АКАРИЦИДНЫЕ ОБРАБОТКИ В СЕЗОН АКТИВНОСТИ КЛЕЩЕЙ</h4>
						<p class="news__text--indent">
						Борьбу с популяциями клещей в природных биотопах проводят в зонах высокого риска заражения населения с помощью инсектоакарицидных (акарицидных) средств, прошедших процедуру государственной регистрации. С учетом значимости приусадебных, дачных и садовых участков как мест контакта населения с клещами в настоящее время ряд средств разрешены для применения населением для самостоятельной обработки участков, находящихся в личном пользовании, в соответствии с текстами этикеток для быта. Список инсектоакарицидных средств, разрешенных для применения с целью уничтожения иксодовых клещей в природных биотопах, размещен в Реестре свидетельств о государственной регистрации (в рамках ТС ЕврАзЭС) по адресу http://fp.crc.ru/.  Средства, разрешенные для применения населением в быту для самостоятельной обработки участков, находящихся в личном пользовании, отмечены особо (в разделе «Примечание»). 
						</p>
						<p class="news__text--indent">
						Результаты обследования на 3–5 сутки после обработки являются основанием для решения вопроса о безопасности пребывания людей на данной территории, а в более поздние сроки — о возможной необходимости повторной обработки (при недостаточной эффективности). На территориях детских учреждений (например, школ и лагерей) противоклещевые обработки являются обязательным условием пребывания там детей.  
						</p>
						<p class="news__text--indent">
						Современные акарицидные препараты сохраняются в верхних слоях лесной подстилки до полутора месяцев и уничтожают активную часть популяции, не затрагивая особей из нижних слоев. Противоклещевые обработки каждой территории должны быть ежегодными, а в ряде случаев необходимо несколько обработок в течение сезона. Это позволит надежно защитить людей, находящихся на опасных в отношении клещей территориях. 
						</p>
						<p class="news__text--indent">
						ФБУЗ «Центр гигиены и эпидемиологии в Запорожской области» предоставляет услуги по проведению акарицидной обработки территории, стоимость которой представлена в разделе Услуги.
						</p>
						<br />
						<h4 class="news__subtitle">Порядок проведения акарицидных обработок во ФБУЗ «Центр гигиены и эпидемиологии в Запорожской области»</h4>
						<p class="news__text--indent">
						В отделе эпидемиологии ФБУЗ «Центр гигиены и эпидемиологии в Запорожской области» по адресу: г. Мелитополь, ул. Сухова 6 можно получить бесплатную консультацию и оставить заявку на проведение акарицидной обработки. Расчет стоимости услуги проводится с учетом площади территорий (в сотках). Важным условием перед проведением акарицидной обработки является обеспечение Заказчиком удовлетворительного санитарного содержания территории, включающее стрижку газонов, уборку листвы и сухой травы, сухостоя, хозяйственного и бытового мусора, а также расчистка прилегающих территорий от мусора, валежника, сухостоя в радиусе 50-100 м вокруг принадлежащего участка. Необходимо предусмотреть энтомологическое (акарологическое) обследование территории до акарицидной обработки с целью определения видового состава и численности иксодовых клещей и повторное энтомологическое обследование для контроля эффективности обработки.   
						</p>		
				`,
        images: [require('@/assets/img/news/6/6_acarus.jpg'), require('@/assets/img/news/6/6_acarus2.webp')],
      },
      {
        id: uuidv4(),
        title: 'Уважаемые Коллеги!',
        alias: 'Kimovich',
        content: `
						<p class="news__text--indent">
						От себя лично и от коллективов Управления Роспотребнадзора по Запорожской области и «ФБУЗ Центр гигиены и эпидемиологии в Запорожской области» выражаю Вам глубокие соболезнования в связи со скоропостижной смертью <strong>Алексея Кимовича Носкова</strong>, директора ФКУЗ Ростовский-на-Дону противочумный институт Роспотребнадзора.
						</p>     
						<br />
						<p class="news__text--indent">
						Ушел из жизни талантливый человек, опытный специалист и профессионал своего дела. Алексей Кимович обладал глубокими знаниями и имел большой практический опыт в обеспечении санитарно-эпидемиологического благополучия населения в мирное время и в вооруженных конфликтах; при организации спасательных мероприятий при ликвидации последствий землетрясения, паводка, опасных метеорологических явлений и в период пандемии. Будучи руководителем института, внес весомый вклад в обеспечение санитарно-эпидемиологического благополучия населения страны, совершенствование эпидемиологического надзора за холерой и другими особо опасными и природно-очаговыми инфекционными болезнями, их лабораторную диагностику и профилактику. 
						</p>
						<br />
						<p class="news__text--indent">
						Алексей Кимович имел заслуженный высокий авторитет в системе Роспотребнадзора и здравоохранения. Его неравнодушие и преданность своему делу восхищали коллег и предопределяли легкость и результативность при взаимодействии по всем вопросам. 
						</p>
						<br />
						<p class="news__text--indent">
						Скорбим вместе с Вами!
						</p>
						<br />
						<p class="news__text--indent">
						Руководитель Управления 
						</p>
						<br />
						<p class="news__text--indent">
						Роспотребнадзора по Запорожской области  <span class="news__text--right">Н.В. Хаттатова</span>
						</p>
				`,
        images: [require('@/assets/img/news/5_noskov-black.jpg')],
      },
      {
        id: uuidv4(),
        title: 'О профилактике холеры',
        alias: 'holera',
        content: `
						<p class="news__text--indent">
						Холера - острая инфекционная болезнь с клиникой кишечной инфекции, которая регистрируется только у людей, как взрослых, так и у детей. Тяжелые случаи связаны с обезвоживанием организма из-за водянистой диареи и рвоты. Возбудитель - холерный вибрион. После заражения клинические проявления чаще возникают в течение 5 дней. Источник инфекции - больной человек, у которого может не быть симптомов заболевания.
						</p>     
						<br />
						<p class="news__text--indent">
						Пути передачи инфекции - водный, пищевой и контактно-бытовой, когда возбудитель из кишечника больного при несоблюдении правил личной гигиены инфицирует воду, пищу, предметы обихода и попадает в организм здорового человека.
						</p>
						<br />
						<p class="news__text--indent">
						Холерой можно заразиться при питье воды или употреблении в пищу продуктов, зараженных возбудителем. Чаще всего источниками инфекций пищевого происхождения являются сырые или не прошедшие достаточную тепловую обработку морепродукты, свежие фрукты и овощи, а также другие продукты, зараженные во время их приготовления или хранения.
						</p>
						<br />
						<p class="news__text--indent">
						Профилактика холеры связана с потреблением чистой питьевой воды и своевременной очисткой сточных вод, соблюдением правил гигиены, использованием чистой воды для мытья продуктов питания и посуды, безопасным приготовлением и хранением пищи.
						</p>
						<br />
						<p class="news__text--indent">
						В Запорожской области осуществляется санитарно-эпидемиологический контроль за водоснабжением, канализацией, очисткой населенных мест. На территории Запорожской области проводится плановый лабораторный контроль по выявлению холерных вибрионов в воде поверхностных водоемов и других объектах окружающей среды.
						</p>
						<br />
						<p class="news__text--indent news__text--bold">
						Населению рекомендуется ответственно относиться к своему здоровью, не заниматься самолечением, при первых признаках кишечной инфекции своевременно обращаться за медицинской помощью.
						</p>
				`,
        images: [require('@/assets/img/news/4_holera.webp')],
      },
      {
        id: uuidv4(),
        title: 'О проведении «горячей линии» по актуальным вопросам защиты прав потребителей в судебном порядке',
        alias: 'hotline',
        content: `
						<p class="news__text--indent">
						Управление Роспотребнадзора по Запорожской области и Федеральное бюджетного учреждения здравоохранения «Центр гигиены и эпидемиологии в Запорожской области» информирует, что в период с 11 по 22 марта 2024 года проводится «горячая линия» по актуальным вопросам защиты прав потребителей в судебном порядке. 
						</p>     
						<br />
						<p class="news__text--indent">В период проведения специалисты Управления проконсультируют граждан о правах потребителей, расскажут, что делать в случае неурегулирования спорной ситуации между продавцом и покупателем, об изменениях в проведении надзорных мероприятий в сфере защиты прав потребителей, ответят на вопросы, касающиеся реализации закона о защите прав потребителей, порядке и способах судебной защиты прав и законных интересов, помогут в оформлении претензий, исковых заявлений в судебные органы, а также дадут ответы на другие актуальные вопросы. </p>
						<br />
						<p class="news__text--indent">За консультацией можно обратиться по телефону «горячей линии» +7 990-234-50-40, а также на личном приеме специалистов по адресу: Запорожская область, г. Мелитополь, ул. Сухова, д. 6.</p>
						<br />
						<p class="news__text--indent">Часы работы горячей линии: Понедельник - Четверг: 09:00 - 17:00; Пятница: 09:00 - 16:45; Перерыв: 13:00 - 13:45.</p>
				`,
        images: [require('@/assets/img/news/3_protection.webp')],
      },
      {
        id: uuidv4(),
        title:
          'Специалисты Роспотребнадзора из Запорожской области начали работу над исследованием различных материалов и проб',
        alias: 'startWork',
        content: `
						<p class="news__text">
						Перед этим сотрудники ведомства прошли обучение и получили комплексные практические навыки, которые помогут им оставаться на страже здоровья жителей  региона.
						<br />
						<br />
						Смотрите За!ТВ  <a href="https://vk.com/za_tv_ru">ВКонтакте</a> и <a href="https://t.me/+ytSQE-ESZuxhZDAy">Telegram</a>
						</p>
				`,
        videos: [require('@/assets/videos/videonew_6.mp4')],
        images: [require('@/assets/img/news/2_news.jpg')],
      },
      {
        id: uuidv4(),
        title: 'Специалисты Роспотребнадзора из новых регионов РФ прошли обучение работе в мобильной лаборатории',
        alias: 'finishEducation',
        content: `				
						<p class="news__text">
						С 27 по 30 ноября на базе Управления Роспотребнадзора по Запорожской области состоялись учения по отработке теоретических и практических занятий по подготовке специалистов для работы в мобильной лаборатории на базе автошасси. Сотрудники Российского противочумного института «Микроб» Роспотребнадзора обучили особенностям работы в передвижной лаборатории 13 сотрудников управлений Роспотребнадзора из Донецкой и Луганской Народных Республик, Запорожской и Херсонской областей. 
						</p>
						<ul class="news__ulList">
							<h4 class="news__ulList-subtitle">Обучение включило в себя: </h4>
							<li class="news__ulList--padding">знакомство с правилами работы с патогенными биологическими агентами 2-4 групп патогенности в соответствии с требованиями биологической безопасности;</li>
							<li class="news__ulList--padding">обсуждение вопросов работы со средствами индивидуальной защиты, используемыми при исследовании возбудителей особо опасных инфекций;</li>
							<li class="news__ulList--padding">объяснение нюансов манипуляций при подготовке проб биологического материала и объектов внешней среды, их отбору, упаковке, транспортировке и условиям хранения. </li>
							<li class="news__ulList--padding">практическая работа на современном лабораторном оборудовании. </li>
						<p class="news__text news__text--marginTop">
							Специалисты получают комплексные практические навыки по работе с ПЦР и ИФА, которые позволят организовать своевременную диагностику инфекционных заболеваний, в том числе идентификацию возбудителей особо опасных инфекций.
						</p>
						</ul>				
				`,
        images: [require('@/assets/img/news/1/pic1.webp'), require('@/assets/img/news/1/pic2.webp')],
      },
    ],
  },
  getters: {},
  mutations: {},
  actions: {},
};

export default NewsStore;
