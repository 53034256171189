<template>
  <NavigationComp :isDisable="false" />
  <main class="structureILC container">
    <h1 class="structureILC__title">Структура ИЛЦ</h1>
    <img src="@/assets/img/content/structureILC.webp" alt="Структура ИЛЦ" />
  </main>
</template>

<script>
import NavigationComp from "@/components/UI/Navigation/NavigationComp.vue";

import { useHead } from "@vueuse/head";

export default {
  components: { NavigationComp },
  name: "Структура ИЛЦ",

  setup() {
    useHead({
      title:
        "Структура ИЛЦ | Федеральное бюджетное учреждение здравоохранения «Центр гигиены и эпидемиологии в Запорожской области»",
      meta: [
        {
          name: "description",
          content:
            "Структура ИЛЦ Центра гигиены и эпидемиологии в Запорожской области.",
        },
      ],
    });
  },
};
</script>

<style lang="scss" scoped></style>
