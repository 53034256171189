<template>
  <footer class="footer">
    <nav class="footer__navigation">
      <div class="footer__navigation_wrapper container">
        <div class="footer__navigation_links left-side">
          <div class="footer__navigation_logo">
            <router-link :to="'./'">
              <img
                class="logo"
                src="@/assets/img/symbols/logo.svg"
                alt="logo"
              />
            </router-link>
            <h3>ФБУЗ «Центр гигиены и эпидемиологии в Запорожской области»</h3>
          </div>
          <div class="footer__navigation_contacts">
            <router-link class="footer__navigation_link" :to="'/filials'"
              >2 филиала в Запорожской области</router-link
            >
          </div>
          <div class="footer__navigation_contacts">
            <a class="footer__navigation_link tel" href="tel:+7-990-234-50-40"
              >+7-990-234-50-40</a
            >
          </div>
        </div>
      </div>
    </nav>
    <div class="footer__wrapper container">
      <h5 class="footer__copy">
        © {{ currentYear }} Федеральное бюджетное учреждение здравоохранения
        «Центр гигиены и эпидемиологии в Запорожской области»
      </h5>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Подвал сайта",

  name: "Footer",
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>
