<template>
  <NavigationComp :isDisable="false" />
  <main class="main">
    <div class="main__wrapper container">
      <h2 class="main__wrapper-title">Заявление на исследование клеща</h2>

      <div class="formForTickResearch">
        <form
          class="formForTickResearch__form"
          @submit.prevent="sendDocumentByEmail"
        >
          <span class="formForTickResearch__form-requiredFieldText">
            Поля, отмеченные *, обязательны для заполнения
          </span>
          <h4 class="formForTickResearch__form-title">Заявитель:</h4>
          <div class="formForTickResearch__form-block">
            <label class="formForTickResearch__form-label" for="fullNameInput"
              >Фамилия Имя Отчество*:</label
            >
            <input
              class="formForTickResearch__form-textInput"
              type="text"
              v-model="formData.fullNameInput"
              id="fullNameInput"
              placeholder="Фамилия Имя Отчество"
              required
            />
            <div class="formForTickResearch__form-checkBoxBlock">
              <input
                class="formForTickResearch__form-checkbox"
                type="checkbox"
                v-model="formData.fullNameCheck"
                id="fullNameCheck"
              />
              <label
                class="formForTickResearch__form-label"
                for="fullNameCheck"
              >
                Совпадает с ФИО лица с которого снят клещ
              </label>
            </div>
          </div>
          <div class="formForTickResearch__form-block">
            <label class="formForTickResearch__form-label" for="addressInput">
              Юридический адрес (регистрация для физического лица)*:
            </label>
            <input
              class="formForTickResearch__form-textInput"
              type="text"
              v-model="formData.addressInput"
              id="addressInput"
              placeholder="Адрес регистрации"
              required
            />
            <div class="formForTickResearch__form-checkBoxBlock">
              <input
                class="formForTickResearch__form-checkbox"
                type="checkbox"
                v-model="formData.addressCheck"
                id="addressCheck"
              />
              <label class="formForTickResearch__form-label" for="addressCheck">
                Совпадает с фактическим адресом проживания
              </label>
            </div>
          </div>
          <div class="formForTickResearch__form-block">
            <label
              class="formForTickResearch__form-label"
              for="addressCurrentInput"
            >
              Фактический адрес (проживания)*:
            </label>
            <input
              class="formForTickResearch__form-textInput"
              type="text"
              v-model="formData.addressCurrentInput"
              id="addressCurrentInput"
              placeholder="Адрес проживания"
              required
            />
          </div>
          <div class="formForTickResearch__form-block">
            <label class="formForTickResearch__form-label" for="inputAddressTel"
              >Ваш номер телефона*:</label
            >
            <input
              class="formForTickResearch__form-textInput"
              type="tel"
              v-model="formData.inputAddressTel"
              id="inputAddressTel"
              placeholder="Введите ваш телефон"
              required
            />
          </div>
          <p>
            Прошу провести лабораторные испытания клеща(ей) на инфекции:
            клещевой энцефалит, боррелиоз (болезнь лайма), анаплазмоз, эрлихиоз.
          </p>
          <div class="formForTickResearch__form-block">
            <div class="formForTickResearch__form-checkBoxBlock">
              <input
                class="formForTickResearch__form-checkbox"
                type="checkbox"
                v-model="formData.confirmCheck"
                id="confirmCheck"
              />
              <label class="formForTickResearch__form-label" for="confirmCheck">
                Даю согласие на направление результатов исследований на
                электронный адрес*:
              </label>
            </div>
            <input
              class="formForTickResearch__form-textInput"
              type="email"
              v-model="formData.emailInput"
              placeholder="email"
              required
            />
          </div>

          <h4>Информация в отношении лица, с которого снят клещ:</h4>
          <div class="formForTickResearch__form-block">
            <label class="formForTickResearch__form-label" for="fullNameInput2"
              >1. Фамилия Имя Отчество*:</label
            >
            <input
              class="formForTickResearch__form-textInput"
              type="text"
              v-model="formData.fullNameInput2"
              id="fullNameInput2"
              placeholder="Фамилия Имя Отчество"
              required
            />
          </div>
          <div class="formForTickResearch__form-block">
            <label
              class="formForTickResearch__form-label"
              for="addressCurrentInput2"
            >
              2. Фактический адрес (проживания)*:
            </label>
            <input
              class="formForTickResearch__form-textInput"
              type="text"
              v-model="formData.addressCurrentInput2"
              id="addressCurrentInput2"
              placeholder="Адрес проживания"
            />
          </div>
          <div class="formForTickResearch__form-block">
            <label class="formForTickResearch__form-label" for="birthdayInput"
              >3. Дата рождения*:</label
            >
            <input
              class="formForTickResearch__form-textInput"
              type="date"
              v-model="formData.birthdayInput"
              id="birthdayInput"
              placeholder="дд.мм.гггг"
              required
            />
          </div>
          <div class="formForTickResearch__form-block">
            <label
              class="formForTickResearch__form-label"
              for="removalLocationInput"
            >
              4. Место снятия клеща с наименованием учреждения (больница,
              поликлиника, МСЧ, фельдшерско-акушерский пункт, травмпункт, удален
              самостоятельно, и др.):
            </label>
            <input
              class="formForTickResearch__form-textInput"
              type="text"
              id="removalLocationInput"
              v-model="formData.removalLocationInput"
              placeholder="Место снятия клеща"
              value="удален самостоятельно"
            />
          </div>
          <div class="formForTickResearch__form-block">
            <label
              class="formForTickResearch__form-label"
              for="biteTerritoryInput"
            >
              5. Территория, где предположительно произошло присасывание клеща:
              (СНТ, лес, парк, сквер, кладбище, санаторий, пансионат и др.,
              адрес: область, район, город, деревня):*:
            </label>
            <input
              class="formForTickResearch__form-textInput"
              type="text"
              v-model="formData.biteTerritoryInput"
              id="biteTerritoryInput"
              placeholder="Укажите адрес"
              required
            />
          </div>
          <div class="formForTickResearch__form-block">
            <label
              class="formForTickResearch__form-label"
              for="childcareFacilityInput"
            >
              5.1. Присасывание произошло на территории детского учреждения
              (лагерь, детский сад. Наименование детского учреждения и его
              адрес):
            </label>
            <input
              class="formForTickResearch__form-textInput"
              type="text"
              v-model="formData.childcareFacilityInput"
              id="childcareFacilityInput"
              placeholder=""
            />
          </div>
          <div class="formForTickResearch__form-block">
            <label class="formForTickResearch__form-label" for="dateOfBiteInput"
              >6. Дата укуса клеща*:</label
            >
            <input
              class="formForTickResearch__form-textInput"
              type="date"
              v-model="formData.dateOfBiteInput"
              id="dateOfBiteInput"
              placeholder="дд.мм.гггг"
              required
            />
          </div>
          <div class="formForTickResearch__form-block">
            <label
              class="formForTickResearch__form-label"
              for="withdrawalDateInput"
              >7. Дата снятия клеща*:</label
            >
            <input
              class="formForTickResearch__form-textInput"
              type="date"
              v-model="formData.withdrawalDateInput"
              id="withdrawalDateInput"
              placeholder="дд.мм.гггг"
              required
            />
          </div>

          <div class="formForTickResearch__form-block">
            <div class="formForTickResearch__form-checkBoxBlock">
              <input
                class="formForTickResearch__form-checkbox"
                type="checkbox"
                v-model="formData.familiarCheck"
                id="familiarCheck"
              />
              <label
                class="formForTickResearch__form-label"
                for="familiarCheck"
              >
                Заявитель ознакомлен с: порядком и условиями проведения
                исследований/испытаний; методами исследований/испытаний.
              </label>
            </div>
            <div class="formForTickResearch__form-checkBoxBlock">
              <input
                class="formForTickResearch__form-checkbox"
                type="checkbox"
                v-model="formData.ruleCheck"
                id="ruleCheck"
              />
              <label class="formForTickResearch__form-label" for="ruleCheck">
                В соответствии с Федеральным законом от 27.07.2006 № 152-ФЗ «О
                персональных данных» даю согласие на обработку моих персональных
                данных, указанных в заявлении и прилагаемых документах.
              </label>
            </div>
            <div class="formForTickResearch__form-checkBoxBlock">
              <input
                class="formForTickResearch__form-checkbox"
                type="checkbox"
                v-model="formData.payCheck"
                id="payCheck"
              />
              <label class="formForTickResearch__form-label" for="payCheck"
                >Оплату работ гарантирую.</label
              >
            </div>
          </div>

          <div class="formForTickResearch__form-buttonsList">
            <button
              class="formForTickResearch__form-button"
              type="button"
              @click="generateAndSaveDocument"
            >
              Получить документ
            </button>
            <button class="formForTickResearch__form-button" type="submit">
              Оформить
            </button>
          </div>

          <span class="formForTickResearch__form-requiredFieldText">
            После нажатия на кнопку «Оформить» Вы перейдете на страницу онлайн
            оплаты. На указанный адрес электронной почты будет направлен договор
            и заполненное заявление. Вам останется распечатать заявление (или
            запомните его номер (он понадобится при сдаче клеща) и сдать клеща
            по адресу: Адрес, г. Мелитополь, ул. Сухова, 6
          </span>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
import NavigationComp from "@/components/UI/Navigation/NavigationComp.vue";
import { Document, Packer, Paragraph, TextRun, ColumnBreak } from "docx";
import { saveAs } from "file-saver";
import emailjs from "emailjs-com";

export default {
  components: { NavigationComp },
  name: "Заявление на исследование клеща",

  data() {
    return {
      formData: {
        fullNameInput: "",
        fullNameCheck: false,
        addressInput: "",
        addressCheck: false,
        addressCurrentInput: "",
        inputAddressTel: null,
        confirmCheck: true,
        emailInput: "",
        fullNameInput2: "",
        addressCurrentInput2: "",
        birthdayInput: null,
        removalLocationInput: null,
        biteTerritoryInput: "",
        childcareFacilityInput: "",
        dateOfBiteInput: null,
        withdrawalDateInput: null,
        familiarCheck: true,
        ruleCheck: true,
        payCheck: true,
      },
      attachment: null,
    };
  },

  methods: {
    generateAndSaveDocument() {
      const doc = new Document({
        sections: [
          {
            children: [
              new Paragraph({
                text: `Фамилия Имя Отчество: ${
                  this.formData.fullNameInput || ""
                }`,
              }),
              new Paragraph({
                text: `Совпадает с ФИО лица с которого снят клещ: ${
                  this.formData.fullNameCheck ? "Да" : "Нет"
                }`,
              }),
              new Paragraph({
                text: `Юридический адрес (регистрация для физического лица: ${
                  this.formData.addressInput || ""
                }`,
              }),
              new Paragraph({
                text: `Совпадает с фактическим адресом проживания: ${
                  this.formData.addressCheck ? "Да" : "Нет"
                }`,
              }),
              new Paragraph({
                text: `Фактический адрес (проживания): ${
                  this.formData.addressCurrentInput || ""
                }`,
              }),
              new Paragraph({
                text: `Ваш номер телефона: ${
                  this.formData.inputAddressTel || ""
                }`,
              }),
              new Paragraph({
                text: `Даю согласие на направление результатов исследований на электронный адрес: ${
                  this.formData.confirmCheck ? "Да" : "Нет"
                }`,
              }),
              new Paragraph({
                text: `Адрес электронной почты: ${
                  this.formData.emailInput || ""
                }`,
              }),
              new Paragraph({
                text: `Фамилия Имя Отчество: ${
                  this.formData.fullNameInput2 || ""
                }`,
              }),
              new Paragraph({
                text: `Фактический адрес (проживания): ${
                  this.formData.addressCurrentInput2 || ""
                }`,
              }),
              new Paragraph({
                text: `Дата рождения: ${this.formData.birthdayInput || ""}`,
              }),
              new Paragraph({
                text: `Место снятия клеща с наименованием учреждения (больница, поликлиника, МСЧ, фельдшерско-акушерский пункт, травмпункт, удален самостоятельно, и др.): ${
                  this.formData.removalLocationInput || ""
                }`,
              }),
              new Paragraph({
                text: `Территория, где предположительно произошло присасывание клеща: (СНТ, лес, парк, сквер, кладбище, санаторий, пансионат и др., адрес: область, район, город, деревня): ${
                  this.formData.biteTerritoryInput || ""
                }`,
              }),
              new Paragraph({
                text: `Присасывание произошло на территории детского учреждения (лагерь, детский сад. Наименование детского учреждения и его адрес): ${
                  this.formData.childcareFacilityInput || ""
                }`,
              }),
              new Paragraph({
                text: `Дата укуса клеща: ${
                  this.formData.dateOfBiteInput || ""
                }`,
              }),
              new Paragraph({
                text: `Дата снятия клеща: ${
                  this.formData.withdrawalDateInput || ""
                }`,
              }),
              new Paragraph({
                text: `Заявитель ознакомлен с: порядком и условиями проведения исследований/испытаний; методами исследований/испытаний: ${
                  this.formData.familiarCheck ? "Да" : "Нет"
                }`,
              }),
              new Paragraph({
                text: `В соответствии с Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных» даю согласие на обработку моих персональных данных, указанных в заявлении и прилагаемых документах: ${
                  this.formData.ruleCheck ? "Да" : "Нет"
                }`,
              }),
              new Paragraph({
                text: `Оплату работ гарантирую: ${
                  this.formData.payCheck ? "Да" : "Нет"
                }`,
              }),
            ],
          },
        ],
      });

      Packer.toBlob(doc).then((blob) => {
        saveAs(
          blob,
          `Заявление на исследование клеща от ${this.formData.fullNameInput}.docx`
        );
      });
    },

    clearForm() {
      this.formData = {
        fullNameInput: "",
        fullNameCheck: false,
        addressInput: "",
        addressCheck: false,
        addressCurrentInput: "",
        inputAddressTel: null,
        confirmCheck: true,
        emailInput: "",
        fullNameInput2: "",
        addressCurrentInput2: "",
        birthdayInput: null,
        removalLocationInput: null,
        biteTerritoryInput: "",
        childcareFacilityInput: "",
        dateOfBiteInput: null,
        withdrawalDateInput: null,
        familiarCheck: true,
        ruleCheck: true,
        payCheck: true,
      };
    },

    sendDocumentByEmail() {
      const doc = new Document({
        sections: [
          {
            children: [
              new Paragraph({
                text: `Фамилия Имя Отчество: ${
                  this.formData.fullNameInput || ""
                }`,
              }),
              new Paragraph({
                text: `Совпадает с ФИО лица с которого снят клещ: ${
                  this.formData.fullNameCheck ? "Да" : "Нет"
                }`,
              }),
              new Paragraph({
                text: `Юридический адрес (регистрация для физического лица: ${
                  this.formData.addressInput || ""
                }`,
              }),
              new Paragraph({
                text: `Совпадает с фактическим адресом проживания: ${
                  this.formData.addressCheck ? "Да" : "Нет"
                }`,
              }),
              new Paragraph({
                text: `Фактический адрес (проживания): ${
                  this.formData.addressCurrentInput || ""
                }`,
              }),
              new Paragraph({
                text: `Ваш номер телефона: ${
                  this.formData.inputAddressTel || ""
                }`,
              }),
              new Paragraph({
                text: `Даю согласие на направление результатов исследований на электронный адрес: ${
                  this.formData.confirmCheck ? "Да" : "Нет"
                }`,
              }),
              new Paragraph({
                text: `Адрес электронной почты: ${
                  this.formData.emailInput || ""
                }`,
              }),
              new Paragraph({
                text: `Фамилия Имя Отчество: ${
                  this.formData.fullNameInput2 || ""
                }`,
              }),
              new Paragraph({
                text: `Фактический адрес (проживания): ${
                  this.formData.addressCurrentInput2 || ""
                }`,
              }),
              new Paragraph({
                text: `Дата рождения: ${this.formData.birthdayInput || ""}`,
              }),
              new Paragraph({
                text: `Место снятия клеща с наименованием учреждения (больница, поликлиника, МСЧ, фельдшерско-акушерский пункт, травмпункт, удален самостоятельно, и др.): ${
                  this.formData.removalLocationInput || ""
                }`,
              }),
              new Paragraph({
                text: `Территория, где предположительно произошло присасывание клеща: (СНТ, лес, парк, сквер, кладбище, санаторий, пансионат и др., адрес: область, район, город, деревня): ${
                  this.formData.biteTerritoryInput || ""
                }`,
              }),
              new Paragraph({
                text: `Присасывание произошло на территории детского учреждения (лагерь, детский сад. Наименование детского учреждения и его адрес): ${
                  this.formData.childcareFacilityInput || ""
                }`,
              }),
              new Paragraph({
                text: `Дата укуса клеща: ${
                  this.formData.dateOfBiteInput || ""
                }`,
              }),
              new Paragraph({
                text: `Дата снятия клеща: ${
                  this.formData.withdrawalDateInput || ""
                }`,
              }),
              new Paragraph({
                text: `Заявитель ознакомлен с: порядком и условиями проведения исследований/испытаний; методами исследований/испытаний: ${
                  this.formData.familiarCheck ? "Да" : "Нет"
                }`,
              }),
              new Paragraph({
                text: `В соответствии с Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных» даю согласие на обработку моих персональных данных, указанных в заявлении и прилагаемых документах: ${
                  this.formData.ruleCheck ? "Да" : "Нет"
                }`,
              }),
              new Paragraph({
                text: `Оплату работ гарантирую: ${
                  this.formData.payCheck ? "Да" : "Нет"
                }`,
              }),
            ],
          },
        ],
      });

      Packer.toBlob(doc)
        .then((blob) => {
          const service_id = "service_cd3yjup";
          const template_id = "template_4oy9e4d";
          const user_id = "7EI79Y5pacE30vV2o";
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            const base64data = reader.result.split(",")[1];

            emailjs.init(user_id);
            emailjs
              .send(service_id, template_id, {
                ...this.formData,
                content: base64data,
                filename: `Заявление на исследование клеща от ${this.formData.fullNameInput}.docx`,
              })
              .then(
                (response) => {
                  alert("Заявление успешно отправлено");
                  console.log("Email sent successfully:", response);
                  this.clearForm();
                },
                (error) => {
                  console.error("FAILED...", error);
                }
              );
          }.bind(this);
        })
        .catch((error) => {
          console.error("Ошибка при создании документа:", error);
        });
    },
  },
};
</script>
