<template>
  <NavigationComp :isDisable="false" />
  <main class="phoneBook container">
    <h1 class="phoneBook__title">Телефонный справочник</h1>
    <table class="phoneBook__table">
      <thead>
        <tr>
          <th class="phoneBook__table-th">
            Наименование структурного подразделения
          </th>
          <th class="phoneBook__table-th">Телефон</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="phoneBook__table-td phoneBook__table-td--weight">
            Горячая линия
          </td>
          <td class="phoneBook__table-td">+7-990-234-50-40</td>
        </tr>
        <tr>
          <td class="phoneBook__table-td phoneBook__table-td--weight">
            Приёмная главного врача
          </td>
          <td class="phoneBook__table-td">+7-990-290-36-38</td>
        </tr>
        <tr>
          <td class="phoneBook__table-td phoneBook__table-td--weight">
            Отдел кадров
          </td>
          <td class="phoneBook__table-td">+7-990-290-36-39</td>
        </tr>
        <tr>
          <td class="phoneBook__table-td phoneBook__table-td--weight">
            Главный бухгалтер
          </td>
          <td class="phoneBook__table-td">+7-990-290-36-40</td>
        </tr>
      </tbody>
    </table>
  </main>
</template>

<script>
import NavigationComp from "@/components/UI/Navigation/NavigationComp.vue";
import { mapState } from "vuex";
import { useHead } from "@vueuse/head";

export default {
  components: { NavigationComp },
  name: "Телефонный справочник",

  computed: {
    ...mapState({
      news: (state) => state.NewsStore.news,
    }),
  },

  setup() {
    useHead({
      title:
        "Телефонный справочник | Федеральное бюджетное учреждение здравоохранения «Центр гигиены и эпидемиологии в Запорожской области»",
      meta: [
        {
          name: "description",
          content:
            "Телефонный справочник Центра гигиены и эпидемиологии в Запорожской области.",
        },
      ],
    });
  },
};
</script>

<style lang="scss" scoped></style>
