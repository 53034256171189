<template>
  <details class="vacancy__details" v-for="item in vacancies" :key="item.id">
    <summary>{{ item.department }}</summary>
    <table
      class="vacancy__details-table"
      v-for="(currentVacancy, index) in item.vacancy"
      :key="index"
    >
      <tbody>
        <tr>
          <td>{{ currentVacancy.title }}</td>
          <td style="width: 10%">{{ currentVacancy.salary }}</td>
        </tr>
      </tbody>
    </table>
  </details>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Вакансия",

  computed: {
    ...mapState({
      vacancies: (state) => state.Vacancy.vacancies,
    }),
  },
};
</script>

<style lang="scss" scoped></style>
